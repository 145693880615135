import React from "react";
import {userConstants} from '../../_constants/userConstants';
import {success} from '../../actions/actions';
import { connect } from 'react-redux';
import axios from 'axios';
import './Login.css'
import Spiner from '../Spiner/Spiner'
import lblSrvc from '../../labelService';
import MySelect from '../MultiSelect/MySelect';


const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '45px',
      height: '45px',
      boxShadow: state.isFocused ? null : null,
      border: '1px solid #26a69a !important'
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '45px',
      padding: '0 6px',
      'flex-direction': 'column',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        marginTop: '10px',
        backgroundColor: '#2ad2c2',
        color: 'white',
    }),
    placeholder: (provided, state) => ({
        display: 'none'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    menu: (provided, state) => ({
        ...provided,
        'background-color' : 'white',
        'z-index': '100000',
      }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '50px',
    }),
    option: (provided, { isSelected }) => {
        if(isSelected){
            return { 
                ...provided,
                backgroundColor: '#26a69a',
            };
        }
        return {
            ...provided,
        }
      },
};

class RegisterForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            username: '',
            firstName: '',
            lastName: '',
            password: '',
            adress: '',
            phoneNumber: '',
            email: '',
            sector: 0,
            sectorOptions: [],
            sectorOptionsEng: [ 
                { value : 1, label: 'Public sector'},
                { value : 2, label: 'Citizens'},
                { value : 3, label: 'Business'},
                { value : 4, label: 'Others'},
            ],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    async handleOnSubmit(e) {
        const M = window.M;

        e.preventDefault();
        const { username, firstName, lastName, password, email, password_confirm, sector } = this.state;
        const { dispatch } = this.props;

        if (!firstName){
            this.setState({ badRegister : true, badRegisterMsg : 'Polje Ime ne smije biti prazno'});
            M.toast({html: 'Polje Ime ne smije biti prazno', displayLength : 3000});
            return;
        }

        if (!lastName){
            this.setState({ badRegister : true, badRegisterMsg : 'Polje Prezime ne smije biti prazno'});
            M.toast({html: 'Polje Prezime ne smije biti prazno', displayLength : 3000});
            return;
        }

        if (!username){
            this.setState({ badRegister : true, badRegisterMsg : 'Polje Korisnicko ime ne smije biti prazno'});
            M.toast({html: 'Polje Korisnicko ime ne smije biti prazno', displayLength : 3000});
            return;
        }

        if (!password){
            this.setState({ badRegister : true, badRegisterMsg : 'Polje Lozinka ime ne smije biti prazno'});
            M.toast({html: 'Polje Ime ne smije biti prazno', displayLength : 3000});
            return;
        }

        if (password != password_confirm){
            this.setState({ badRegister : true, badRegisterMsg : 'Lozinke se ne poklapaju'});
            M.toast({html: 'Lozinke se ne poklapaju', displayLength : 3000});
            return;
        }

        let user  = {
           'username' : username,
           'firstName': firstName,
           'lastName': lastName,
           'email': email,
           'pass' : password, 
           'valid' : '2h', 
           'lang' : this.props.selectedLanguage,
           'sector' : sector,
        };

        await this.setState({ loading: true });

        axios.put('/api/setUser', user)
        .then(response => {
          
            let server_data = response.data;
            if (server_data.msg == ''){
                localStorage.setItem(userConstants.MEIS_EDU_TOKEN, server_data.idToken);

                let user_data = { username : user.username, id : server_data.id, isAdmin : false}
                localStorage.setItem(userConstants.USER_DATA, JSON.stringify(user_data));

                this.setState({ loading: false, badRegister : false, badRegisterMsg : 'ok'});
                dispatch(success(username));

                //this.props.history.push("/");
                if(this.props.onHandleLogin)
                    this.props.onHandleLogin(user_data);
            }
            else {
                this.setState({loading: false, badRegister : true, badRegisterMsg : server_data.msg});
                M.toast({html: server_data.msg, displayLength : 3000});
            }
        }
        ,error => {
            this.setState({ loading: false, badLogin : true, badLoginMsg : 'Došlo je do greške prilikom registracije'});
        });

    }
    
    componentDidMount(){
        this.getSectors();
    }

    async getSectors(){
        await this.setState({ loading: true });

        axios.get("/api/getSectors",  {  })
            .then(res => {
                
                this.setState({ sectorOptions: res.data});
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
        });
    }

    generateOptions = (data) => {
        return data.map( option => {
            return (<option value={option.value}> {option.label} </option>);
        });
    }
 
    handleLogin = () => {
        if (this.props.onHandleLogin)
            this.props.onHandleLogin();
    }

    getSelectObject = (data, value) => {
        let obj = { value : 0, label: ''};

        for (let i = 0; i < data.length; i++) {
            if(data[i].value === value) 
                obj = data[i];
        }
        return [obj];
    }

    render(){

        return(
            <div className="root-bg">
                {this.state.loading &&
                    <Spiner></Spiner>
                }
                <div className="container">
                    <div className="row">
                        <div className="col s12">
                            <div className="card-panel" style={{marginTop : 155}}>
                                <div className="centered-container">
                                    {this.props.selectedLanguage == 'ENG' &&
                                        <img src={require("./SCC logo-01.png")} className="scc-header-image" width="300"/>
                                    }
                                    {this.props.selectedLanguage != 'ENG' &&
                                        <img src={require("./SCC logo-02.png")} className="scc-header-image" width="300"/>
                                    }
                                </div>
                                <div className="centered-container" style={{marginBottom: '20px'}}>
                                    <h5>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_REGISTRATION')}</h5>
                                </div>
                                <div className="row">
                                    <form className="col s12">
                                        {this.state.badRegister &&
                                            <div className="centered-container" style={{marginBottom : 20}}>
                                                <div className="lbl-err-txt">{this.state.badRegisterMsg}</div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col s12 m6">
                                                <div className="row mlp0">
                                                    <div className="input-field col s12 mtp0">
                                                        <input id="firstName" type="text" name="firstName" onChange={this.handleChange}/>
                                                        <label htmlFor="firstName">{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_FIRST_NAME')}*</label>
                                                    </div>
                                                </div>

                                                <div className="row mlp0">
                                                    <div className="input-field col s12 mtp0">
                                                        <input id="lastName" type="text" name="lastName" onChange={this.handleChange}/>
                                                        <label htmlFor="lastName">{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_LAST_NAME')}*</label>
                                                    </div>
                                                </div>

                                                <div className="row mlp0">
                                                    <div className="input-field col s12 mtp0">
                                                        <input id="email" type="text" name="email" onChange={this.handleChange}/>
                                                        <label htmlFor="email">{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_EMAIL')}</label>
                                                    </div>
                                                </div>
                                                <div className="row mlp0">
                                                    <div className="input-field col s12 mtp0">
                                                        <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SECTOR')}</span>
                                                        <MySelect 
                                                            maxHeight={30}
                                                            options={this.props.selectedLanguage === 'MNE' ? this.state.sectorOptions : this.state.sectorOptionsEng}
                                                            value={this.getSelectObject(this.state.sectorOptions, this.state.sector)}
                                                            onChange={selected => this.setState({sector : selected.value}) }
                                                            isMulti={false}
                                                            styles={selectCustomStyles}
                                                            isSearchable={true}
                                                            allowSelectAll={false}
                                                            hideSelectedOptions={false}
                                                            lang={this.props.selectedLanguage}
                                                        />
                                                        {this.state.err_frequencyPayment &&
                                                            <div className="lbl-err-txt">{this.state.err_frequencyPayment_msg}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col s12 m6">
                                                <div className="row mlp0">
                                                    <div className="input-field col s12 mtp0">
                                                        <input id="username" type="text" name="username" onChange={this.handleChange}/>
                                                        <label htmlFor="username">{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_USERNAME')}*</label>
                                                    </div>
                                                </div>

                                                <div className="row mlp0">
                                                    <div className="input-field col s12 mtp0">
                                                        <input id="password" type="password" name="password" onChange={this.handleChange}/>
                                                        <label htmlFor="password">{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_PASSWORD')}*</label>
                                                    </div>
                                                </div>

                                                <div className="row mlp0">
                                                    <div className="input-field col s12 mtp0">
                                                        <input id="password_confirm" type="password" name="password_confirm" onChange={this.handleChange}/>
                                                        <label htmlFor="password_confirm">{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_PASSWORD_CONFIRM')}*</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mlp0">
                                            <div className="input-field col s12 mtp0">
                                                <a href="javascript:void(0)" className="waves-effect waves-light btn darken-4"
                                                    onClick={this.handleOnSubmit}>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_SIGN_UP')}</a>
                                            </div>
                                        </div>

                                        <div className="row centered-container" style={{marginLeft: '20px', marginRight: '20px'}}>
                                            <div>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_HAVE_ACCOUNT')} <a style={{marginLeft: '0px'}} href="javascript:void(0)" onClick={this.handleLogin}>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_LOGIN')}</a></div>
                                        </div>
                                    </form>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;

    return {
        loggedIn, user, selectedLanguage
    };
}


const connectedRegisterForm = (connect(mapStateToProps)(RegisterForm));
export {connectedRegisterForm as RegisterForm};
