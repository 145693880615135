export const userConstants = {
    LOGIN_REQUEST:      'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS:      'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE:      'USERS_LOGIN_FAILURE',
    LOGOUT:             'USERS_LOGOUT',
    GETALL_REQUEST:     'USERS_GETALL_REQUEST',
    GETALL_SUCCESS:     'USERS_GETALL_SUCCESS',
    GETALL_FAILURE:     'USERS_GETALL_FAILURE', 
    MEIS_EDU_TOKEN:     'MEIS_EDU_TOKEN', 
    SET_CLASSES:        'SET_CLASSES', 
    CLEAR_CLASSES:      'CLEAR_CLASSES',
    USER_DATA:          'USER_DATA',
    REG_COUNT:          'REG_COUNT'
};