import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import  { connect } from 'react-redux';
import  { selectLicence } from '../../../../actions/licenseActions';
import  { selectRegister } from '../../../../actions/RegisterActions';
import ReactTooltip from 'react-tooltip';

export class TableRow extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {

    }

    handleClick = () => {
        this.props.selectLicence(this.props.data.id);
    }

    onEditLicence = () => {
        this.props.selectLicence(this.props.data.id);
    }

    onEditRegister(e,id) {
        e.preventDefault();
        this.props.onSearchLevy(id)
    }

    getValidValue(value){
        if(value == null || value == undefined) return '';
        value = value.toString()
        if(value.length > 35){
            value = value.substring(0,35)+ '...';
        }

        return value;
    }

    openLicenceLink(e, link){
        if (!link)
            return;
        
        e.preventDefault();
        if (link.startsWith("Portal"))
            window.open("https://www.euprava.me", "_blank");
        else
            window.open(link, "_blank");
    }

    render() {
        let licence = this.props.data;

        return (
            <tr>
                <td className="scc-table-cell">{licence.name}</td>
                <td className="scc-table-cell">{licence.nameeng}</td>
                <td className="scc-table-cell"  data-for='levy'>
                    <a onClick={(e) => this.openLicenceLink(e,licence.link)}>
                        {licence.link}
                    </a>
                </td>
                <td className="scc-table-cell" data-tip={licence.levy} data-for='levy'>
                    <a onClick={(e) => this.onEditRegister(e,licence.levy)}   >
                        {this.getValidValue(licence.levy)}
                    </a>
                    <ReactTooltip id='levy' type='' effect='solid'>
                    </ReactTooltip>
                </td>
                <td className="scc-table-cell" data-tip={licence.ind_levy1} data-for='ind_levy1'>
                    <a onClick={(e) => this.onEditRegister(e,licence.ind_levy1)}  >
                        {this.getValidValue(licence.ind_levy1)}
                    </a>
                    <ReactTooltip id='ind_levy1' type='' effect='solid'>
                    </ReactTooltip>
                </td>
                <td className="scc-table-cell" data-tip={licence.ind_levy2} data-for='ind_levy2'>
                    <a onClick={(e) => this.onEditRegister(e,licence.ind_levy2)}  >
                        {this.getValidValue(licence.ind_levy2)}
                    </a>
                    <ReactTooltip id='ind_levy2' type='' effect='solid'>
                    </ReactTooltip>
                </td>
                <td className="scc-table-cell" data-tip={licence.ind_levy3} data-for='ind_levy3'>
                    <a onClick={(e) => this.onEditRegister(e,licence.ind_levy3)}  >
                        {this.getValidValue(licence.ind_levy3)}
                    </a>  
                    <ReactTooltip id='ind_levy3' type='' effect='solid'>
                    </ReactTooltip>
                </td>
                <td className="scc-table-cell" data-tip={licence.ind_levy4} data-for='ind_levy4'>
                    <a onClick={(e) => this.onEditRegister(e,licence.ind_levy4)}  >
                        {this.getValidValue(licence.ind_levy4)}
                    </a>         
                    <ReactTooltip id='ind_levy4' type='' effect='solid'>
                    </ReactTooltip>
                </td>
            </tr>
        )
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { selectLicence, selectRegister })(TableRow);