import axios from 'axios';
import {userConstants} from './_constants/userConstants';

let history = null;

export default {    
    setupInterceptors: (store) => {

        axios.interceptors.response.use(response => {            
            return response;
        }, error => {

            if (error.response.status === 401) {
                if (history) {history.push('/login');}
            }
            return Promise.reject(error);
        });

        axios.interceptors.request.use(
            function(config) {
                const token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);      
                //console.log('token', token);                      
                if ( token != null ) {
                    config.headers.Authorization = 'Bearer ' + token;
                }

                return config;
            }, 
            function(err) {
                return Promise.reject(err);
            }
        );
    },
    setHistory : (_history) => {history = _history;}
};
