import { combineReducers} from 'redux';
import LanguageReducer from "./LanguageReducer";
import RegisterReducer from './RegisterReducer';
import AuthReducer from './AuthReducer';
import LicenceReducer from './LicenceReducer';

export default combineReducers({
    language: LanguageReducer,
    registers: RegisterReducer,
    licences: LicenceReducer,
    authentication: AuthReducer,
});