import React, { Component } from 'react';
import  { connect } from 'react-redux';
import  lblSrvc from '../../labelService';
import { userConstants } from "../../_constants/userConstants";
import axios from "axios";
import Header  from '../Header/Header';
import Spiner from '../Spiner/Spiner'
import MySelect from './MySelect';
import moment from 'moment';

const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '45px',
      height: '45px',
      boxShadow: state.isFocused ? null : null,
      border: '1px solid #26a69a !important'
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '45px',
      padding: '0 6px',
      'flex-direction': 'column',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        marginTop: '10px',
        backgroundColor: '#2ad2c2',
        color: 'white',
    }),
    placeholder: (provided, state) => ({
        display: 'none'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    menu: (provided, state) => ({
        ...provided,
        'background-color' : 'white',
        'z-index': '100000',
      }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '50px',
    }),
    option: (provided, { isSelected }) => {
        if(isSelected){
            return { 
                ...provided,
                backgroundColor: '#26a69a',
            };
        }
        return {
            ...provided,
        }
       
      },
  };


class Reports extends Component {
    constructor(props) {
        super(props);

        this.state = {
           loading : false,
           isGroup: false,
           selected: undefined,
           table_data: undefined, 
           visitorscount : undefined
        };
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        //check if is it admin 
        this.loadUser();
        setTimeout(() =>
            {this.loadVisitors();}, 
            500);
    }

    loadUser() {
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data || !data.isAdmin) {
            this.props.history.push('/');
        }
    }

    loadVisitors = () => {

        axios.get("/api/visitorscount", {params: { lang: this.props.selectedLanguage }})
            .then(res => {
                this.setState({visitorscount : res.data});
            })
            .catch((error) => {
        });
    }

    /*getReports = () => {
        let { selected, isGroup } = this.state;

        if(selected == undefined || !selected.value) return;
        
        axios.get("/api/getReports", {params: { selected, isGroup, lang: this.props.selectedLanguage }} )
            .then(res => {
                this.setState({ table_data: res.data});
            })
            .catch((error) => {
        });
    }*/
    
    exportExcelByChange = () => {

        axios.get("/api/exportExcelReportsByChange", {params: {  lang: this.props.selectedLanguage }, responseType: 'blob'} )
            .then(res => {
                this.openBlob(res.data,  'xlsx');
            })
            .catch((error) => {
        });
    }

    exportExcelCommentsReport = () => {

        axios.get("/api/exportExcelCommentsReport", {params: { lang: this.props.selectedLanguage }, responseType: 'blob'} )
            .then(res => {
                this.openBlob(res.data,  'xlsx');
            })
            .catch((error) => {
        });
    }

    exportExcelChangesReport = () => {

        axios.get("/api/exportExcelChangesReport", {params: { lang: this.props.selectedLanguage }, responseType: 'blob'} )
            .then(res => {
                this.openBlob(res.data,  'xlsx');
            })
            .catch((error) => {
        });
    }

    exportCoefReport = () => {

        axios.get("/api/konkurentnost", {params: { lang: this.props.selectedLanguage }, responseType: 'blob'} )
            .then(res => {
                this.openBlob(res.data,  'xlsx');
            })
            .catch((error) => {
        });
    }

    exportExcel = () => {
        let { selected } = this.state;
        let isGroup = !this.state.isGroup;
        if(selected == undefined || !selected.value) return;


        axios.get("/api/exportExcelReport", {params: { selected, isGroup, lang : this.props.selectedLanguage }, responseType: 'blob'} )
            .then(res => {
                this.openBlob(res.data,  'xlsx');
            })
            .catch((error) => {
        });
    }

    openBlob(data, outType){
        var file;
        if (outType == 'word'){
            file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
        }
        else
        {
            if (outType == 'pdf'){
                file = new Blob([data], {type: 'application/pdf'});
            }
            else
            {
                file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                file = new File([file], 'korisnici.xlsx', {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            }    
        }
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
       
    }

    getAllOptionObj(){
        if (this.props.selectedLanguage === 'MNE')
            return {label: "Odaberi sve", value: "*"};
        else 
            return {label: "Select all", value: "*"};
    }

    getNoneOptionObj(){
        if (this.props.selectedLanguage === 'MNE')
            return {label: "Poništi sve", value: "#"};
        else 
            return {label: "Unselect all", value: "#"};
    }



    getOptions = () => {
        let options = [
            {value: 1, label: 'Primjena', label: lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_TYPES_LOCATION')},
            {value: 2, label: lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_TYPES_AREALVL1')},
            {value: 3, label: 'Vrsta', label: lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_TYPES_TYPE')},
            {value: 4, label: 'Institucija', label: lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_TYPES_INSTITUTION')},
            {value: 5, label: 'Način plaćanja', label: lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_TYPES_PAYMENT_TYPE')},
            {value: 6, label: 'Kada se plaća', label: lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_TYPES_PAYMENT_TIME')},
        ];
        /**
            let options = [
                {value: 1, label: 'Primjena', label: lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_TYPES')},
                {value: 2, label: 'Oblast nivo 1'},
                {value: 3, label: 'Vrsta'},
                {value: 4, label: 'Institucija'},
                {value: 5, label: 'Način plaćanja'},
                {value: 6, label: 'Vrijeme plaćanja'},
            ];
        */

        if(!this.state.isGroup){
            options.shift();
        }

        return options;
    }

    generateTableHeader = (columns) => {
        let cols = [];

        for (let i = 0; i < columns.length; i++) {
            cols.push(<th>{columns[i].header}</th>);
        }

        return cols;
    }

    generateTableRows = (data) => {
        let rows = [];

        for (let i = 0; i < data.length; i++) {
            let date = moment(data[i].naziv);
            if(date.isValid()){
                rows.push(<tr><td>{date.format("MM/DD/YYYY")}</td><td>{data[i].count}</td></tr>);
            }else
                rows.push(<tr><td>{data[i].naziv}</td><td>{data[i].count}</td></tr>);
        }

        return rows;
    }


    generateTable = () => {
        let { table_data } = this.state;
        if(table_data == undefined || !(table_data.length <= 0)) return null;
        return (
            <table>
                <thead>
                 <tr>{this.generateTableHeader(table_data.columns)}</tr>

                </thead>
                <tbody>
                     {this.generateTableRows(table_data.data)}
                </tbody>
            </table>
        )

    }

    render() {
        let options = this.getOptions();

        return (
            <div>
                {this.state.loading &&
                    <Spiner></Spiner>
                }
                <Header />
                <div className="scc-page-container scc-min-height" >
                    <div className="container" style={{marginTop: 60}}>
                        <div className="row centered-container">
                            <h4>{lblSrvc.getLabel(this.props.selectedLanguage, 'REPORTS_TITLE')}</h4>
                        </div>
                        <div className="row">
                            <h5>{"Broj posjeta: " + this.state.visitorscount}</h5>
                        </div>
                        <div className="report-form card" style={{marginTop: 55, marginBottom: 65, padding: 45, boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 0px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)'}}>
                            <div className="row">
                                <div className="col-sm-9">
                                    <form>
                                        <div className="col s12 m6">
                                            <div className="input-field col s12">
                                                <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_TYPES')}</span>
                                                <MySelect 
                                                    maxHeight={30}
                                                    options={options}
                                                    value={this.state.selected}
                                                    onChange={selected => this.setState({selected})}
                                                    isMulti={false}
                                                    styles={selectCustomStyles}
                                                    isSearchable={true}
                                                    allowSelectAll={false}
                                                    hideSelectedOptions={false}
                                                    lang={this.props.selectedLanguage}
                                                    allOption={this.getAllOptionObj()}
                                                    noneOption={this.getNoneOptionObj()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col s12 m6 l3">
                                            <div className="row" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 47}}>
                                                <div style={{paddingRight: 10}}>
                                                    <label>
                                                        <input className="with-gap" name="group1" type="radio"  checked={!this.state.isGroup} onChange={() => this.setState({ isGroup : false})}/>
                                                        <span>{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME')}</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input className="with-gap" name="group1"  type="radio" checked={this.state.isGroup} onChange={() => this.setState({ isGroup : true})}/>
                                                        <span>{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SORTED_NAME')}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12 m12 l3">
                                            <div style={{ marginTop: 35}}>
                                                <button onClick={() => this.exportExcel()} className="btn waves-effect waves-light right" type="button" name="addBtn" style={{ width: '100%', marginLeft : 23, height: 45, paddingLeft : '0.8rem'}} >
                                                    {lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_BUTTON')}
                                                    <i className="material-icons left">arrow_downward</i>
                                                </button>
                                            </div>
                                        </div>                                        
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="" style={{marginTop: 35}}> 
                                        <button onClick={() => this.exportExcelByChange()} className="btn waves-effect waves-light right" type="button" name="addBtn" style={{ width: '100%', marginLeft : 0, height: 45, paddingLeft : '0.8rem'}} >
                                            {lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_BUTTON_SECOND')}
                                            <i className="material-icons left">arrow_downward</i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col">
                                   
                                </div>
                                <div className="col">
                                    <div className="" style={{marginTop: 35}}> 
                                        <button onClick={() => this.exportExcelCommentsReport()} className="btn waves-effect waves-light right" type="button" style={{ width: '100%', marginLeft : 0, height: 45, paddingLeft : '0.8rem'}} >
                                            {lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_BUTTON_COMMENTS')}
                                            <i className="material-icons left">arrow_downward</i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="" style={{marginTop: 35}}> 
                                        <button onClick={() => this.exportExcelChangesReport()} className="btn waves-effect waves-light right" type="button" style={{ width: '100%', marginLeft : 0, height: 45, paddingLeft : '0.8rem'}} >
                                            {lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_BUTTON_CHANGES')}
                                            <i className="material-icons left">arrow_downward</i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="" style={{marginTop: 35}}> 
                                        <button onClick={() => this.exportCoefReport()} className="btn waves-effect waves-light right" type="button" style={{ width: '100%', marginLeft : 0, height: 45, paddingLeft : '0.8rem'}} >
                                            {lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_BUTTON_COEFCOM')}
                                            <i className="material-icons left">arrow_downward</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;

    return {
        loggedIn, user, selectedLanguage, 
    };
}

const connectedReports = (connect(mapStateToProps)(Reports));
export {connectedReports as Reports};

