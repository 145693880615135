export const MneLabels = {
    HOME                                : 'Početna',
    CONTACT                             : 'Kontakt',
    REPORTS                             : 'Izvještaji',
    GOAL                                : 'Registar nameta sadrži spisak nameta na državnom i lokalnom nivou, koje su u obavezi da plaćaju fizička i pravna lica. Osnovni cilj kreiranja ovog registra je centralizacija podataka o nametima koji se naplaćuju u Crnoj Gori, na državnom i lokalnom nivou, a u cilju pojednostavljenja procesa poslovanja i povećanja transparentnosti.',
    GOAL_FULL                           : 'Nameti su opisani, između ostalog, atributima koji definišu: vrstu, visinu nameta, način uplate, oblast ili sektor za koji se primjenjuju, propise koji su osnov za donošenje i utvrđivanje iznosa nameta. Pitanje registra koji objedinjava fiskalne namete na nacionalnom i lokalnom nivou je već duže vrijeme važna tema inicirana i od strane Vlade i privrednih udruženja, kao mjera za unapređenje poslovnog ambijenta u Crnoj Gori. Registar omogućava javnom sektoru da ima uvid u opterećenost nametima po sektorima privrede, analizira neophodnost postojećih nameta i na osnovu tih inputa donosi odluku u kojim slučajevima se namet može smanjiti, odložiti ili ukinuti. Ova baza treba da pomogne donosiocima odluka pri izradi nove regulative i utvrđivanja novih nameta. Sa druge strane, za privredu ovakav registar omogućuje da na transparentan način ima uvid u listu nameta i iznos istih za oblasti koje se odnose na njihovo poslovanje, kao i mogućnost da unaprijed izračunaju i planiraju troškove koji se odnose na njihove obaveze prema javnom sektoru. Registar je dinamična baza koja se mijenja u skladu sa izmjenama regulative koja propisuje namete na državnom i lokalnom nivou. Datum posljednje promjene nameta se može pratiti kroz atribut „datum poslednje promjene podataka“. Nameti koji su sadržani u ovom registru su: akcize, dažbine, donacije, doprinosi, hartije od vrijednosti, kamate, krediti, kazne, naknade, porezi i prirezi, pozajmice, prihodi, prodaja, takse, troškovi i carine. ',
    ABOUT_SCC                           : 'Sekretarijat Savjeta za konkurentnost, podržan od strane EBRD-a, u saradnji sa Vladom Ujedinjenog Kraljevstva, koordinirao je i finansirao izradu registra, u saradnji sa radnom grupom koja je sastavljena od članova javnih institucija, privrednih udruženja i Zajednice opština. Radna grupa je pratila proces realizacije projekta i u svakoj fazi doprinosila kvalitetu registra davanjem sugestija i komentara.',
    SEARCH                              : 'Pretraga',
    SEARCH_TABLE                        : 'Pokreni pretragu',
    MAIN_TITLE                          : 'Registar nameta',
    MAIN_SUB_TITLE                      : 'Sve informacije na jednom mjestu',
    MAIN_BUTTON_LABEL                   : 'Pretraga',
    MAIN_GRAPH_SECTION_TITLE            : 'O registru',
    MAIN_GRAPH_SECTION_SUB_TITLE        : 'NOVI DIZAJN',
    MAIN_GRAPH_SECTION_TEXT             : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In molestie, diam id tincidunt vestibulum, arcu turpis tempor eros, eget gravida enim magna a nulla. Morbi ornare massa vel tellus aliquet, ac tempus eros ornare. Aenean dictum augue sed luctus bibendum. Fusce a sagittis odio.',
    MAIN_CARDS_TITLE                    : '',
    SEARCH_PAGE_TITLE                   : 'Tabela registara',
    SEARCH_PAGE_SUB_TITLE               : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In molestie, diam id tincidunt vestibulum.',
    SEARCH_PAGE_TABLE_TITLE             : 'Pretraga registra nameta',
    SEARCH_PAGE_TABLE_SUB_TITLE         : 'Tabela',
    REGISTER_TABLE_COLUMN_STATE         : 'Status',
    REGISTER_TABLE_COLUMN_REG_NUMBER    : 'Šifra nameta',
    REGISTER_TABLE_COLUMN_NAME          : 'Naziv',
    REGISTER_TABLE_COLUMN_SORTED_NAME   : 'Razvrstani naziv',
    REGISTER_TABLE_COLUMN_GROUP_NAME    : 'Grupisani naziv',
    REGISTER_TABLE_COLUMN_NAME_ENG      : 'Naziv na engleskom',
    REGISTER_TABLE_COLUMN_AMOUNT        : 'Iznos nameta',
    REGISTER_TABLE_COLUMN_INSTITUTION   : 'Institucija',
    REGISTER_TABLE_COLUMN_LOCATION      : 'Primjena',
    REGISTER_TABLE_COLUMN_SECTOR        : 'Sektor',
    REGISTER_TABLE_COLUMN_SCRIPT        : 'Propis',
    REGISTER_TABLE_COLUMN_STATE         : 'Status',
    REGISTER_TABLE_COLUMN_REG_SCRIPT    : 'Propis nameta',
    REGISTER_TABLE_COLUMN_SCRIPT_AMOUNT : 'Propis iznosa nameta',
    REGISTER_TABLE_COLUMN_TAX           : 'Kada se plaća',
    REGISTER_TABLE_COLUMN_COST_FROM     : 'Iznos od',
    REGISTER_TABLE_COLUMN_COST_TO       : 'Iznos do',
    REGISTER_TABLE_COLUMN_COST_PRE_TO   : 'Iznos u procentima do',
    REGISTER_TABLE_COLUMN_COST_PRE_FROM : 'Iznos u procentima od',
    REGISTER_TABLE_COLUMN_TYPE          : 'Vrsta nameta',
    REGISTER_TABLE_COLUMN_FREQUENCY     : 'Periodičnost plaćanja',
    REGISTER_TABLE_COLUMN_PAYMENT_TYPE  : 'Način plaćanja',
    REGISTER_TABLE_COLUMN_BASE          : 'Osnovica',
    REGISTER_TABLE_COLUMN_ORGAN         : 'Organ',
    REGISTER_TABLE_COLUMN_OBLIGATOR     : 'Obveznik plaćanja',
    REGISTER_TABLE_COLUMN_OBLIGATOR_TYPE: 'Vrsta obveznika',
    REGISTER_TABLE_COLUMN_DATE          : 'Datum početka primjene',
    REGISTER_TABLE_COLUMN_BANK_ACC      : 'Žiro-račun',
    REGISTER_TABLE_COLUMN_CODE_D        : 'Šifra D',
    REGISTER_TABLE_COLUMN_CODE_N        : 'Šifra N',
    REGISTER_TABLE_COLUMN_EXCEPTIONS    : 'Izuzeci',
    REGISTER_TABLE_COLUMN_DATE_UPDATE   : 'Datum ažuriranja',
    REGISTER_TABLE_COLUMN_PROCEDURE_NUM : 'Broj procedure',
    REGISTER_TABLE_COLUMN_PROCEDURE     : 'Procedura',
    REGISTER_TABLE_ROWS_DISPLAYED       : 'Prikazano',
    REGISTER_TABLE_ROWS_DISPLAYED_OF    : 'od',
    REGISTER_TABLE_ROWS                 : 'redova',
    REGISTER_TABLE_SEARCH               : 'Traži',            
    FOOTER_TITLE                        : 'Naslov za futer',
    FOOTER_SUB_TITLE                    : 'Tekst za futer',
    FOOTER_LINK                         : 'Prvi link',
    FOOTER_COPYRIGHT                    : '© 2020 Copyright',
    FOOTER_BOTTOM_RIGHT                 : 'Jos linkova',
    FOOTER_LINK_TITLE                   : 'Linkovi',
    SEARCH_PAGE_COMMENTS_TITLE          : 'Unijeti komentar',
    LOGIN                               : 'Prijavi se',
    LOGOUT                              : 'ODJAVI SE',
    SCC_COMMENT_TEXT                    : 'Unesite Vaš komentar',
    SCC_COMMENT_SEND                    : 'POŠALJI',
    SCC_COMMENT_BACK                    : 'Nazad',
    SCC_COMMENT_TEXT_DISABLED           : 'Prijavite se kako bi ostavili komentar',
    SCC_FOOTER_TEXT                     : 'Copyright © Ministarstvo ekonomskog razvoja 2020',
    ADD_REGISTER                        : 'Dodaj novi namet',
    TOAST_DEFINE_FIELD                  : 'Obavezno polje',
    NAME                                : 'Naziv',
    CANCEL                              : 'Odustani',
    ADD                                 : 'Dodaj',
    SAVE                                : 'Sačuvaj podatke',
    ADMIN_REGISTER_TITLE                : 'Ažurirajte podatke o nametu',
    ADMIN_ADD_REGISTER_TITLE            : 'Dodajte novi namet',
    USER_REGISTER_TITLE                 : 'Pregledajte podatke o nametu',
    SCC_BANNER_COMMENTS_TITLE           : 'Napomena za komentare',
    SCC_BANNER_SEARCH_TITLE_FIRST       : 'U cilju unapređenja registra, omogućeno je postavljanje komentara. U slučaju kada je namet već u registru, komentari se upisuju za svaki namet pojedinačno, uz neophodnu registraciju i prijavu korisnika. U slučajevima kada namet, koji postoji u praksi, nije u bazi, komentari se mogu dati slanjem mail-a na kontakt adresu scc@scc.org.me.',
    SCC_BANNER_SEARCH_TITLE_SECOND      : 'Pretraga registra se vrši po svim atributima koji postoje u bazi. Za jedan atribut je moguće izabrati više opcija.',
    SCC_BANNER_PUBLIC_SECTOR            : 'Javni sektor: Registar omogućava da javni sektor unaprijedi transparentnost i olakša uvid u namete na državnom i lokalnom nivou. Registar je dobar input za donošenje odluka o smanjenju iznosa ili ukidanju nameta, kao i pri planiranju nove regulative i utvrđivanja novih nameta.',
    SCC_BANNER_CITIZENS                 : 'Građani: Registar omogućava jednostavan uvid u obaveze koje građani imaju prema državnoj upravi i lokalnoj samoupravi.',
    SCC_BANNER_ECONOMY                  : 'Privreda: Registar olakšava privredi pretragu nameta koje su u obavezi da izmiruju u cilju nesmetanog obavljanja privrednih aktivnosti. Takođe, privreda ima mogućnost da unaprijed izračuna i planira troškove koji se odnose na njihove obaveze prema javnom sektoru.',
    PREVIUOS                            : 'Prethodna',
    NEXT                                : 'Sljedeća',
    REGISTER_TABLE_COLUMN_AREA1         : 'Klasifikacija propisa nivo 1',
    REGISTER_TABLE_COLUMN_AREA2         : 'Klasifikacija propisa nivo 2',
    READ_MORE                           : 'Detaljnije',
    READ_LESS                           : 'Sakrij',
    PUBLIC_SECTOR                       : 'Registar i javni sektor', 
    PUBLIC_SECTOR_TEXT                  : 'Registar omogućava da javni sektor unaprijedi transparentnost i olakša uvid u namete na državnom i lokalnom nivou. Registar je dobar input za donošenje odluka o smanjenju iznosa ili ukidanju nameta, kao i pri planiranju nove regulative i utvrđivanja novih nameta.', 
    CITIZENS                            : 'Registar i građani', 
    CITIZENS_TEXT                       : 'Registar omogućava jednostavan uvid u obaveze koje građani imaju prema državnoj upravi i lokalnoj samoupravi.', 
    ECONOMY                             : 'Registar i privreda', 
    ECONOMY_TEXT                        : 'Registar olakšava privredi pretragu nameta koje je u obavezi da izmiri u cilju nesmetanog obavljanja privrednih aktivnosti. Takođe, privreda ima mogućnost da unaprijed izračuna i planira troškove koji se odnose na njihove obaveze prema javnom sektoru.',
    SCC_MIN_FINANCY_MNE                 : 'Ministarstvo ekonomskog razvoja',
    SCC_REGISTER                        : 'Registar nameta',
    SCC_LOGIN                           : 'Prijava',
    SCC_USERNAME                        : 'Korisničko ime',
    SCC_PASSWORD                        : 'Lozinka',
    SCC_NO_ACCOUNT                      : 'Nemate nalog?',
    SCC_REGISTRATION                    : 'Registracija',
    SCC_FIRST_NAME                      : 'Ime',
    SCC_LAST_NAME                       : 'Prezime',
    SCC_EMAIL                           : 'E-mail',
    SCC_PASSWORD_CONFIRM                : 'Lozinka (potvrda)',
    SCC_SIGN_UP                         : 'Registruj se',
    SCC_HAVE_ACCOUNT                    : 'Imate nalog?',
    MAIN_CONTACT_SECTION_TITLE          : 'Kontaktirajte nas',
    MAIN_CONTACT_SECTION_ADRESS         : 'Adresa',
    MAIN_CONTACT_SECTION_CALL_US        : 'Pozovite nas',
    MORE_INFO                           : 'Više informacija',
    REPORTS_TITLE                       : 'Preuzmite izvještaje',
    REPORT_ERROR                        : 'Prijavite grešku u radu na e-mail scc@scc.org.me',
    REPORT_TYPES                        : 'Način grupisanja',
    REPORT_BUTTON                       : 'Preuzmi izvještaj',
    REPORT_BUTTON_SECOND                : 'Promjena iznosa nameta',
    EDIT                                : 'Izmijeni',
    READ_MORE                           : 'Detaljnije',
    CONTACT_EMAIL                       : 'Kontak i prijavite grešku u radu na e-mail : ',
    EMAIL_ADRESS                        : 'scc@scc.org.me',
    CONTACT_NUMBER                      : 'Broj SCC Službe : ',
    PHONE_NUMBER                        : '+ 382 20651678',
    CONTACT_LOCATION                    : 'Glavna lokacija : ',
    LOCATION_ADRESS                     : 'Podgorica bb',
    SCC_GROUP_COUNT_LABEL               : 'Broj nameta u Registru',
    SCC_TYPE_COUNT_LABEL                : 'Broj razvrstanih nameta u Registru', 
    SCC_REG_COUNT_LABEL                 : 'Propisi u kojima su definisani osnov ili iznos nameta', 
    USER_MANUAL                         : 'Uputstvo', 
    SCC_FORGOTTEN_PASSWORD              : 'Zaboravili ste šifru?', 
    SCC_SEND_VIA_MAIL                   : 'Pošalji na e-mail adresu', 
    SEND                                : 'Pošalji',
    REPORT_BUTTON_COMMENTS              : 'Preuzmi komentare',
    REPORT_BUTTON_CHANGES               : 'Log izmjena',
    REPORT_BUTTON_COEFCOM               : 'Koeficijenti konkurentnosti',
    REPORT_TYPES_LOCATION               : 'Primjena',
    REPORT_TYPES_AREALVL1               : 'Klasifikacija propisa nivo 1',
    REPORT_TYPES_TYPE                   : 'Vrsta',
    REPORT_TYPES_INSTITUTION            : 'Institucija',
    REPORT_TYPES_PAYMENT_TYPE           : 'Način plaćanja',
    REPORT_TYPES_PAYMENT_TIME           : 'Kada se plaća',
    SAVE_DATA_SUCCESS                   : 'Podaci su uspješno sačuvani',
    SAVE_DATA_ERROR                     : 'Došlo je do greške prilikom čuvanja podataka',
    SELECT_AREALVL1                     : "Morate odabrati makar jednu stavku kod 'Klasifikacija propisa nivo 1'",
    LICENCES_PAGE_TABLE_TITLE           : 'Pretraga licenci',
    SCC_BANNER_LICENCE_TITLE_FIRST      : 'Pretraga nameta po licencama se vrši tako što se izabere naziv određene licence kroz opadajući meni ili se ukuca dio naziva licence. ',
    SCC_BANNER_LICENCE_TITLE_SECOND     : 'Rezultat pretrage je spisak nameta koje je potrebno platiti da bi se dobila licenca.',
    SCC_BANNER_LICENCE_TITLE_THIRD      : 'Postoji „direktni“ namet, koji je vezan isključivo za određenu licencu i „indirektni“ namet koji se može naći kod više licenci i obično se veže za pribavljanje potrebne dokumentacije za dobijanje licence.',
    LICENCE_COL_NAME                    : 'Naziv licence',
    LICENCE_COL_NAME_ENG                : 'Naziv licence na engleskom',
    LICENCE_COL_LEVY_LINK               : 'Link e-licence',
    LICENCE_COL_LEVY_DIR                : 'Direktni namet br. 1',
    LICENCE_COL_LEVY_CODE               : 'Šifra direktnog nameta br. 1',
    LICENCE_COL_LEVY_IND1               : 'Direktni namet br. 2',
    LICENCE_COL_LEVY_IND1_CODE          : 'Šifra direktnog nameta br. 2',
    LICENCE_COL_LEVY_IND2               : 'Direktni namet br. 3',
    LICENCE_COL_LEVY_IND2_CODE          : 'Šifra direktnog nameta br. 3',
    LICENCE_COL_LEVY_IND3               : 'Indirektni namet br. 1',
    LICENCE_COL_LEVY_IND3_CODE          : 'Šifra indirektnog nameta br. 1',
    LICENCE_COL_LEVY_IND4               : 'Indirektni namet br. 2',
    LICENCE_COL_LEVY_IND4_CODE          : 'Šifra indirektnog nameta br. 2',
    LICENCE_COL_COMMENT                 : 'Unijeti komentar',
    TAB_REGISTERS                       : 'Nameti',
    TAB_LICENCES                        : 'Licence',
    DETAIL_SEARCH                       : 'Detaljna pretraga',
    SIMPLE_SEARCH                       : 'Opšta pretraga',
    REGISTER_GEN_SEARCH_TERM            : 'Termin za pretragu',
};