import React, { Component } from 'react'
import  { connect } from 'react-redux';
import  { selectLanguage } from '../../actions/languageActions';
import  lblSrvc from '../../labelService';

export class Footer extends Component {
    render() {
        return (
            <div>
                <div className="hide_on_phone">
                    <div className="footer">

                        <div className="spacebtw-container" style={{minHeight : 250, width : '100%', margin : 0}}>
                            <div className="">
                                { this.props.selectedLanguage == 'ENG' ?
                                <img src={require("./scc_logo_eng.png")} width="300" className="" style={{marginTop : 5, marginLeft : 25}}/>
                                :
                                <img src={require("./scc_logo_mne.png")} width="300" className="" style={{marginTop : 5, marginLeft : 25}}/>
                                }
                            </div>
                            <div className="centered-container">
                                    <div className="col s12 m4 center">
                                        <img src={require("./EBRD-blue-15mm-E.png")} width="280" className="" style={{marginTop : 5, marginRight : 0}}/>
                                    </div>
                                    <div className="col s12 m4 center">
                                        { this.props.selectedLanguage == 'ENG' ?
                                        <img src={require("./brit_abm_ENG.png")} width="200" className="" style={{marginTop : 5, marginRight : 15}}/>
                                        :
                                        <img src={require("./brit_abm_MNE.png")} width="200" className="" style={{marginTop : 5, marginRight : 15}}/>
                                        }
                                    </div>
                                    <div className="col s12 m4 center">
                                        <img src={require("./UK_AID.png")} width="110" className="" style={{marginTop : 5, marginRight : 15}}/>
                                    </div>
                            </div>
                         </div>
                        <div className="centered-container" style={{height : 50, backgroundColor : '#58808c', width : '100%', margin : 0}}>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_FOOTER_TEXT')}</div>
                    </div>
                </div>


                <div className="show_on_phone_only">
                    <div className="footer ">
                        <div className="row" style={{minHeight : 250, width : '100%', margin : 0}}>
                            <div className="col centered-container" style={{marginTop : 5, marginLeft : 20, width : '100%', marginTop : 30, marginBottom : 30}}>
                                { this.props.selectedLanguage == 'ENG' ?
                                <img src={require("./scc_logo_eng.png")} width="200" className="" />
                                :
                                <img src={require("./scc_logo_mne.png")} width="200" className=""/>
                                }
                            </div>
                            <div className="row">
                                <div className="col s12 m4 center" style={{marginTop : 5, marginRight : 0}}>
                                    <img src={require("./EBRD-blue-15mm-E.png")} width="200" className=""/>
                                </div>
                                <div className="col s12 m4 center" >
                                    { this.props.selectedLanguage == 'ENG' ?
                                    <img src={require("./brit_abm_ENG.png")} width="120" className="" style={{marginTop : 5,  marginLeft : 70, marginRight : 0, marginBottom : 10}}/>
                                    :
                                    <img src={require("./brit_abm_MNE.png")} width="120" className="" style={{marginTop : 5,  marginLeft : 70, marginRight : 0, marginBottom : 10}}/>
                                    }
                                </div>
                                <div className="col s12 m4 center">
                                    <img src={require("./UK_AID.png")} width="80" className="" style={{marginTop : 5, marginRight : 0}}/>
                                </div>
                            </div>
                         </div>
                         <div className="centered-container" style={{height : 50, backgroundColor : '#58808c', width : '100%', margin : 0}}>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_FOOTER_TEXT')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, { selectLanguage })(Footer);