let initState = { data : [], searchResults : [], count : 0, isLoading : false, reg_count: 0};

export default (state = initState, action) => {
    
    switch(action.type) {
      case 'SEARCH_LICENCE':
        return {...state, data : action.licences, count : action.licencesCount, searchedLicenceData : action.searchedLicenceData };
      case 'LOADING' :
          return {...state, isLoading : action.isLoading};
      case 'SELECT_LICENCE' :
          return {...state, selectedLicence : action.selectedLicence};
      default:
        return state;
    }
};

