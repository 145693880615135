import React, { Component } from 'react'
import { connect } from 'react-redux';
import lblSrvc from '../../../labelService';

export class ContactSection extends Component {
    constructor(props){
        super(props);
        this.state = {
            readmore : true,
        }
    }
    
    getClass = () =>
    {
        if(this.state.readmore){
            return "sidebar-box scc-main-paragraph";
        }
        else {
            return "sidebar-box";
        }
    }

    showMore = () =>{
        this.setState({readmore : false})
    }

    showLess = () =>{
        this.setState({readmore : true})
    }

    render() {

        return (
            <div className="scc-contact-section" >
                <div className="center-align scc-contact-section-title">
                    <div className="whoAreWe">{lblSrvc.getLabel(this.props.selectedLanguage, 'MAIN_CONTACT_SECTION_TITLE')}</div>
                </div>
                <div className="container ">
                    <div className="row centered-container">
                        {/**
                         * <div  className="col s12 m6" >
                            <div className="center">
                                <img src={require("./adress.png")}  width="96" className="center"/>
                            </div>
                            <div style={{ width: '100%'}}>checked
                                <h5 className="center">{lblSrvc.getLabel(this.props.selectedLanguage, 'MAIN_CONTACT_SECTION_ADRESS')}</h5>
                                <p className="center">
                                    <span className="text-light">{lblSrvc.getLabel(this.props.selectedLanguage, 'CONTACT_LOCATION')}{lblSrvc.getLabel(this.props.selectedLanguage, 'LOCATION_ADRESS')}<br/>
                                        {lblSrvc.getLabel(this.props.selectedLanguage, 'CONTACT_EMAIL')}{lblSrvc.getLabel(this.props.selectedLanguage, 'EMAIL_ADRESS')}<br/>
                                        Tel: {lblSrvc.getLabel(this.props.selectedLanguage, 'PHONE_NUMBER')}<br/>
                                        <a href="#">{lblSrvc.getLabel(this.props.selectedLanguage, 'MORE_INFO')}</a><br/>
                                        <a href="#">{lblSrvc.getLabel(this.props.selectedLanguage, 'REPORT_ERROR')}</a>
                                    </span>
                                </p>
                            </div>
                        </div>
                         */}
                        <div  className="col s12 m12">
                            <div className="center">
                                <img src={require("./msg.png")}  width="96" className="center"/>
                            </div>
                            <div style={{ width: '100%'}}>
                                <p className="center">
                                    <span className="text-light">
                                        {lblSrvc.getLabel(this.props.selectedLanguage, 'CONTACT_EMAIL')}<a href={"mailto: scc@scc.org.me"}>{lblSrvc.getLabel(this.props.selectedLanguage, 'EMAIL_ADRESS')}</a><br/>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {  })(ContactSection);
