import React, { Component } from 'react'
import  { connect } from 'react-redux';
import  lblSrvc from '../../../labelService';
import  { getSearchedRegisters, getNumberOfRegisters, selectRegister} from '../../../actions/RegisterActions';
import { userConstants } from "../../../_constants/userConstants";
import axios from "axios";
import {Link} from 'react-router-dom';
import MySelect from '../../MultiSelect/MySelect';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sr from 'date-fns/locale/sr-Latn';
import Spiner from '../../Spiner/Spiner';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import  './SearchForm.css';
registerLocale('sr-Latn', sr);


const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '45px',
      height: '45px',
      boxShadow: state.isFocused ? null : null,
      border: '1px solid #26a69a !important'
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '45px',
      padding: '0 6px',
      'flex-direction': 'column',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        marginTop: '10px',
        backgroundColor: '#2ad2c2',
        color: 'white',
    }),
    placeholder: (provided, state) => ({
        display: 'none'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    menu: (provided, state) => ({
        ...provided,
        'background-color' : 'white',
        'z-index': '100000',
      }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '50px',
    }),
    option: (provided, { isSelected }) => {
        if(isSelected){
            return { 
                ...provided,
                backgroundColor: '#26a69a',
            };
        }
        return {
            ...provided,
        }
       
      },
  };

export class SearchForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            register_code: undefined,
            name: undefined, //Naziv
            search : '',
            searchGeneralTerm : '',
            prescription : '',
            location_name : '',
            location : 0, //OpstinaId
            state : '', //Status
            use : '',
            law : '',
            bylaw : '',
            type : 0, // VrstaId
            record_bill : '',
            classification_group : '',
            bank_account : '', //ZiroRacun
            note : '',
            loading: false,
            searchType: 'general',
            //new
            nameEng: '', //NazivNaEng
            scriptRegister: '', //PropisNamet
            scriptAmount: '', //PropisIznoss
            organ: '', //Organ
            groupName: '', //Razvrstano ime
            nameByGroup : '',
            institution: '', //Institucija
            dateBegin: '', //DatumPocetka
            dateUpdate:  '', //DatumUpdate
            frequencyPayment: 0, //FrekvencijaPlacanjaId
            exceptions: '', //Izuzeci
            paymentType: 0, //NacinPlacanjaId
            taxPayment: 0, //TaksaPlacanjaId
            paymentObligor: '', //ObveznikPlacanja
            paymentObligorType: '', //VrstaObveznikPlacanja
            base: '', //Osnovica
            amount: '', //Iznos
            dateBegin: '',
            isAdmin: false,
            areaLvl1: 0,
            areaLvl2: 0,
            loggedIn: false,
            selectedLocations: [],
            selectedSectors: [],
            selectedFrequency: [], 
            selectedPaymentType: [], 
            selectedTypes: [], 
            selectedAreaLvl1: [], 
            selectedAreaLvl2: [], 
            selectedTaxPayment: [],
            selectedProcedure: [],
            selectedPaymentObligators: [],
            paymentDate: '',
            codes : { locations: [], types: [], sectors: [], frequency: [], paymentType: [], taxPayment: [], areaLvl1: [], areaLvl2: [], procedure: [], paymentObligors: []},
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getAllCode = this.getAllCode.bind(this);
    }

    componentDidMount(){
        this.setState({ search : this.props.search_result.input });
        this.getAllCode();
        this.loadUser();
    }

    getValidDate = (date) => {
        if(moment(date).isValid()){
            let text = moment(date).format('YYYY-MM-DD');
            return text;
        }else {
            return '';
        }
    }

    onCreateNewRegister = () => {
        this.props.selectRegister(this.state.register_id);
    }

    handleChangeDate = date => {
        let validDate = this.getValidDate(date);
        this.setState({
            dateBegin: date,
            paymentDate: validDate
        });
    }

    loadUser() {
       
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data ) return;
        
        if(data.isAdmin){
            this.setState({ isAdmin: true, loggedIn: true});
        }else if(data.username){
            this.setState({ loggedIn: true});
        }
    }

    async getAllCode(){
        //await this.setState({ loading: true });
        axios.get("/api/getAllCode",  {  })
            .then(res => {
                this.setState({ codes: res.data});
            })
            .catch(function(error) {
              
        });
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value });
    }

    handleChange = location => {
        this.setState({ location_name : location.label, location : location});
    }

    handleSubmit = () => {
        let searchInputs = {
            register_code: this.state.register_code,
            name: this.state.name,
            startId: 0,
            bank_account: this.state.bank_account,
            base: this.state.base,
            nameEng: this.state.nameEng,
            scriptRegister: this.state.scriptRegister,
            scriptAmount: this.state.scriptAmount, 
            organ: this.state.organ, 
            institution: this.state.institution, 
            dateBegin: this.state.dateBegin, 
            exceptions: this.state.exceptions, 
            paymentObligor: this.state.paymentObligor, 
            amount: this.state.amount,
            selectedLocations: this.state.selectedLocations,
            selectedFrequency: this.state.selectedFrequency,
            selectedPaymentType: this.state.selectedPaymentType,
            selectedTypes: this.state.selectedTypes,
            selectedAreaLvl1: this.state.selectedAreaLvl1,
            selectedAreaLvl2: this.state.selectedAreaLvl2,
            selectedTaxPayment: this.state.selectedTaxPayment,
            selectedProcedure: this.state.selectedProcedure,
            selectedPaymentObligators : this.state.selectedPaymentObligators, 
            groupName: this.state.groupName,
            nameByGroup : this.state.nameByGroup, 
            paymentDate: this.state.paymentDate,
            searchGeneralTerm : this.state.searchGeneralTerm, 
            searchType : this.state.searchType, 
            isAdmin: this.state.isAdmin
        };
        this.props.getSearchedRegisters(searchInputs);
        this.props.onRefreshTable();
    }

    exportExcel = async () => {

        let searchInputs = {
            register_code: this.state.register_code,
            name: this.state.name,
            startId: 0,
            bank_account: this.state.bank_account,
            base: this.state.base,
            nameEng: this.state.nameEng,
            scriptRegister: this.state.scriptRegister,
            scriptAmount: this.state.scriptAmount, 
            organ: this.state.organ, 
            institution: this.state.institution, 
            dateBegin: this.state.dateBegin, 
            exceptions: this.state.exceptions, 
            paymentObligor: this.state.paymentObligor, 
            amount: this.state.amount,
            selectedLocations: this.state.selectedLocations,
            selectedFrequency: this.state.selectedFrequency,
            selectedPaymentType: this.state.selectedPaymentType,
            selectedTypes: this.state.selectedTypes,
            selectedAreaLvl1: this.state.selectedAreaLvl1,
            selectedAreaLvl2: this.state.selectedAreaLvl2,
            selectedTaxPayment: this.state.selectedTaxPayment,
            selectedProcedure: this.state.selectedProcedure,
            selectedPaymentObligators: this.state.selectedPaymentObligators,
            groupName: this.state.groupName, 
            nameByGroup : this.state.nameByGroup, 
            searchType : this.state.searchType, 
            searchGeneralTerm  : this.state.searchGeneralTerm 
        };

        await this.setState({ loading: true });
        let url = '/api/exportexcell';
        
        if (this.state.isAdmin){
            url = '/api/exportexcelladmin';
        }

        setTimeout(() => {
            axios.get(url, {params: { searchInputs: searchInputs}, responseType: 'blob'} )
                .then(res => {
                    this.setState({ loading: false });
                    this.openBlob(res.data,  'xlsx');
                })
                .catch((error) => {
                    this.setState({ loading: false });
            });
        }, 1000);
        
    }

    openBlob(data, outType){
        var file;
        if (outType == 'word'){
            file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
        }
        else
        {
            if (outType == 'pdf'){
                file = new Blob([data], {type: 'application/pdf'});
            }
            else
            {
                file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                file = new File([file], 'korisnici.xlsx', {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            }    
        }
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
       
    }

    generateProcedureOptions = (options) => {
        if(options == undefined || options == null) return [];
        return options.map( option => {
            return { value: option.value, label: option.value + '. ' + option.label };
        });
    }

    getAllOptionObj(){
        if (this.props.selectedLanguage === 'MNE')
            return {label: "Odaberi sve", value: "*"};
        else 
            return {label: "Select all", value: "*"};
    }

    getNoneOptionObj(){
        if (this.props.selectedLanguage === 'MNE')
            return {label: "Poništi sve", value: "#"};
        else 
            return {label: "Unselect all", value: "#"};
    }

    getAreaLevel2 = () => {
        let areaLvl2 = this.state.codes.areaLvl2;
        let { selectedAreaLvl1} = this.state;

        if(areaLvl2 == undefined || selectedAreaLvl1.length <= 0) {
            return [];
        }

        let newAreaLvl2 = [];
        for (let i = 0; i < areaLvl2.length; i++) {
            for (let j = 0; j < selectedAreaLvl1.length; j++) {
                if(areaLvl2[i].arealvl1id === parseInt(selectedAreaLvl1[j].value)){
                    newAreaLvl2.push(areaLvl2[i]);
                }
            }
        }
        return newAreaLvl2;
    }

    /*<div className="input-field col s12">
                                        <input  id="register_code" name="register_code" type="number" className="validate" value={this.state.register_code ? this.state.register_code : ''} onChange={this.handleInputChange}></input>
                                        <label htmlFor="register_code">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_NUMBER')}</label>
                                    </div> */

    /*<div className="input-field col s12">
                                        <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_PROCEDURE')}</span>
                                        <MySelect 
                                            maxHeight={30}
                                            options={this.generateProcedureOptions(procedure)}
                                            value={this.state.selectedProcedure}
                                            onChange={selectedProcedure => this.setState({selectedProcedure})}
                                            isMulti={true}
                                            styles={selectCustomStyles}
                                            isSearchable={true}
                                            allowSelectAll={true}
                                            hideSelectedOptions={false}
                                            lang={this.props.selectedLanguage}
                                            allOption={this.getAllOptionObj()}
                                            noneOption={this.getNoneOptionObj()}
                                        />
                                    </div>*/
    isAreaLvl2Disabled = () => {
        let result = true;

        if (this.state.selectedAreaLvl1 && this.state.selectedAreaLvl1.length > 0)
            result = false;
        
        return result;
    }

    getAreaLevel2ToolTip = () =>{
        if (!this.isAreaLvl2Disabled())
            return '';

        return lblSrvc.getLabel(this.props.selectedLanguage, 'SELECT_AREALVL1');
    }

    onChangSelectedAreaLvl1(selectedAreaLvl1){
        if (selectedAreaLvl1 && selectedAreaLvl1.length == 0){
            this.setState({selectedAreaLvl2:[]});
        }
        this.setState({selectedAreaLvl1});
    }

    getNameFieldValue(){
        let { nameFromLicence } = this.props;
        let { name } = this.state;

        if(!nameFromLicence) return name;
        if(name || name == '') return name;
        else {
            this.setState({ name: nameFromLicence});
            setTimeout(() => {
                const M = window.M;
                M.updateTextFields();
            }, 300);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.nameFromLicence !== this.props.nameFromLicence) {
            this.setState({ name: this.props.nameFromLicence});
            setTimeout(() => {
                const M = window.M;
                M.updateTextFields();
            }, 300)
        }
    }

    changeSearchType = () => {
        //console.log('here');
        let newSearch = this.state.searchType == 'detail'?'general':'detail';
        this.setState({ searchType: newSearch });

        setTimeout(() => {
            const M = window.M;
            M.updateTextFields();
        }, 300);

    }

    render() {
        let {locations,  frequency, paymentType, types, areaLvl1, taxPayment, paymentObligors} = this.state.codes;
        let lvl2Areas = this.getAreaLevel2();   

        return (
            <div>
                {this.state.loading &&
                    <Spiner></Spiner>
                }
                <div className="row">
                    <div className="col-sm-9" style={{ margin: 25}}>
                        <div className="row " style={{ margin: 0, padding : 0}}>
                            <div className="detailsearch-header">
                                <a onClick={() => this.changeSearchType()}>{this.state.searchType == 'detail'?lblSrvc.getLabel(this.props.selectedLanguage, 'SIMPLE_SEARCH'):lblSrvc.getLabel(this.props.selectedLanguage, 'DETAIL_SEARCH')}</a>
                            </div>
                        </div>
                        <form onSubmit={this.handleSubmit} >
                            {this.state.searchType == 'general' &&
                            <div className="row" style={{ margin: 0, padding : 0}}>                            
                                 <div className="col s12 m4">
                                    <div className="input-field col s12">
                                        <input   id="searchGeneralTerm" name="searchGeneralTerm" type="text" className="validate" value={this.state.searchGeneralTerm} onChange={this.handleInputChange} />
                                        <label htmlFor="searchGeneralTerm">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_GEN_SEARCH_TERM')}</label>
                                    </div>
                                </div>
                                <div className="col s12 m4">
                                    <div className="input-field col s12">
                                        <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_LOCATION')}</span>
                                        <MySelect 
                                            maxHeight={30}
                                            options={locations}
                                            value={this.state.selectedLocations}
                                            onChange={selectedLocations => this.setState({selectedLocations})}
                                            isMulti={true}
                                            styles={selectCustomStyles}
                                            isSearchable={true}
                                            allowSelectAll={true}
                                            hideSelectedOptions={false}
                                            lang={this.props.selectedLanguage}
                                            allOption={this.getAllOptionObj()}
                                            noneOption={this.getNoneOptionObj()}
                                        />
                                    </div>
                                </div>
                                <div className="col s12 m4">
                                    <div className="input-field col s12">
                                        <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TYPE')}</span>
                                        <MySelect 
                                            maxHeight={30}
                                            options={types}
                                            value={this.state.selectedTypes}
                                            onChange={selectedTypes => this.setState({selectedTypes})}
                                            isMulti={true}
                                            styles={selectCustomStyles}
                                            isSearchable={true}
                                            allowSelectAll={true}
                                            hideSelectedOptions={false}
                                            lang={this.props.selectedLanguage}
                                            allOption={this.getAllOptionObj()}
                                            noneOption={this.getNoneOptionObj()}
                                        />
                                    </div>  
                                </div>
                            </div>}
                            {this.state.searchType == 'detail' &&
                            <div>
                                <div className="row" style={{ margin: 0, padding : 0}}>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input   id="name" name="name" type="text" className="validate" value={this.state.name} onChange={this.handleInputChange} />
                                            <label htmlFor="name">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME')}</label>
                                        </div>
                                    </div>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input   id="nameEng" name="nameEng" type="text" className="validate" value={this.state.nameEng} onChange={this.handleInputChange} />
                                            <label htmlFor="nameEng">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME_ENG')}</label>
                                        </div>    
                                    </div>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input  id="groupName" name="groupName" type="text" className="validate" value={this.state.groupName ? this.state.groupName : ''} onChange={this.handleInputChange}></input>
                                            <label htmlFor="groupName">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SORTED_NAME')}</label>
                                        </div>
                                    </div>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input  id="nameByGroup" name="nameByGroup" type="text" className="validate" value={this.state.nameByGroup ? this.state.nameByGroup : ''} onChange={this.handleInputChange}></input>
                                            <label htmlFor="nameByGroup">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_GROUP_NAME')}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ margin: 0, padding : 0}}>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_LOCATION')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={locations}
                                                value={this.state.selectedLocations}
                                                onChange={selectedLocations => this.setState({selectedLocations})}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                isSearchable={true}
                                                allowSelectAll={true}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                                allOption={this.getAllOptionObj()}
                                                noneOption={this.getNoneOptionObj()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TYPE')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={types}
                                                value={this.state.selectedTypes}
                                                onChange={selectedTypes => this.setState({selectedTypes})}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                isSearchable={true}
                                                allowSelectAll={true}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                                allOption={this.getAllOptionObj()}
                                                noneOption={this.getNoneOptionObj()}
                                            />
                                        </div>  
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-field col s12">
                                            <input  id="scriptRegister" name="scriptRegister" type="text" className="validate" value={this.state.scriptRegister} onChange={this.handleInputChange}></input>
                                            <label htmlFor="scriptRegister"> {lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_SCRIPT')}</label>
                                        </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-field col s12">
                                            <input id="scriptAmount" name="scriptAmount" type="text" className="validate" value={this.state.scriptAmount} onChange={this.handleInputChange}></input>
                                            <label htmlFor="scriptAmount">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SCRIPT_AMOUNT')}</label>
                                        </div>
                                </div>
                                
                                </div>

                                <div className="row" style={{ margin: 0, padding : 0}}>
                                    <div className="col s12 m3">
                                    <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AREA1')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={areaLvl1}
                                                value={this.state.selectedAreaLvl1}
                                                onChange={selectedAreaLvl1 => this.onChangSelectedAreaLvl1(selectedAreaLvl1)}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                isSearchable={true}
                                                allowSelectAll={true}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                                allOption={this.getAllOptionObj()}
                                                noneOption={this.getNoneOptionObj()}
                                            />
                                        </div>
                                </div>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12 tooltipped" data-for='selectedAreaLvl2Tooltip' data-tip={this.getAreaLevel2ToolTip()}>
                                            <span className="multiselect-label ">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AREA2')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={lvl2Areas}
                                                value={this.state.selectedAreaLvl2}
                                                onChange={selectedAreaLvl2 => this.setState({selectedAreaLvl2})}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                isSearchable={true}
                                                allowSelectAll={true}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                                allOption={this.getAllOptionObj()}
                                                noneOption={this.getNoneOptionObj()}
                                                isDisabled={this.isAreaLvl2Disabled()}
                                                
                                            />
                                            {this.isAreaLvl2Disabled() &&
                                            <ReactTooltip id='selectedAreaLvl2Tooltip' type='' effect='solid'>
                                            </ReactTooltip>
                                            }
                                        </div>
                                    </div>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_DATE')}</span>
                                            <DatePicker
                                                selected={this.state.dateBegin}
                                                onChange={this.handleChangeDate}
                                                placeholderText={this.props.selectedLanguage == 'ENG' ? 'MM.DD.YYYY' : 'MM.DD.GGGG'}
                                                locale={this.props.selectedLanguage == 'ENG' ? 'en' : 'sr-Latn'}
                                                dateFormat="dd.MM.yyyy"
                                            />
                                        </div>
                                    </div>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input id="amount" name="amount" type="text" className="validate" value={this.state.amount } onChange={this.handleInputChange}></input>
                                            <label htmlFor="amount">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AMOUNT')}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ margin: 0, padding : 0}}>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input  id="base" name="base" type="text" className="validate" value={this.state.base ? this.state.base : ''} onChange={this.handleInputChange}></input>
                                            <label htmlFor="base">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_BASE')}</label>
                                        </div>  
                                    </div>

                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input  id="bank_account" name="bank_account" type="text" className="validate" value={this.state.bank_account ? this.state.bank_account : ''} onChange={this.handleInputChange}></input>
                                            <label htmlFor="bank_account">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_BANK_ACC')}</label>
                                        </div>  
                                    </div>

                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_PAYMENT_TYPE')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={paymentType}
                                                value={this.state.selectedPaymentType}
                                                onChange={selectedPaymentType => this.setState({selectedPaymentType})}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                isSearchable={true}
                                                allowSelectAll={true}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                                allOption={this.getAllOptionObj()}
                                                noneOption={this.getNoneOptionObj()}
                                            />
                                        </div> 
                                    </div>

                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TAX')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={taxPayment}
                                                value={this.state.selectedTaxPayment}
                                                onChange={selectedTaxPayment => this.setState({selectedTaxPayment})}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                isSearchable={true}
                                                allowSelectAll={true}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                                allOption={this.getAllOptionObj()}
                                                noneOption={this.getNoneOptionObj()}
                                            />
                                        </div>                                  
                                    </div>                          
                                </div>

                                <div className="row" style={{ margin: 0, padding : 0}}>
                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_FREQUENCY')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={frequency}
                                                value={this.state.selectedFrequency}
                                                onChange={selectedFrequency => this.setState({selectedFrequency})}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                isSearchable={true}
                                                allowSelectAll={true}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                                allOption={this.getAllOptionObj()}
                                                noneOption={this.getNoneOptionObj()}
                                            />
                                        </div>
                                    </div>

                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input  id="institution" name="institution" type="text" className="validate" value={this.state.institution ? this.state.institution : ''} onChange={this.handleInputChange}></input>
                                            <label htmlFor="institution">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_INSTITUTION')}</label>
                                        </div>
                                    </div>

                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <input  id="paymentObligor" name="paymentObligor" type="text" className="validate" value={this.state.paymentObligor ? this.state.paymentObligor : ''} onChange={this.handleInputChange}></input>
                                            <label htmlFor="paymentObligor">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_OBLIGATOR')}</label>
                                        </div> 
                                    </div>

                                    <div className="col s12 m3">
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_OBLIGATOR_TYPE')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={paymentObligors}
                                                value={this.state.selectedPaymentObligators}
                                                onChange={selectedPaymentObligators => this.setState({selectedPaymentObligators})}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                isSearchable={true}
                                                allowSelectAll={true}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                                allOption={this.getAllOptionObj()}
                                                noneOption={this.getNoneOptionObj()}
                                            />
                                        </div> 
                                    </div>
                                </div>
                            </div>}
                            <div className="row" >
                                <div className="col s12 m6" style={{marginTop : 15}}>
                                    <button className="btn waves-effect waves-light" type="button" name="searchBtn" style={{maxWidth : 250, height: 50}} onClick={this.handleSubmit}>
                                        {lblSrvc.getLabel(this.props.selectedLanguage, 'SEARCH_TABLE')}
                                        <i className="material-icons left">search</i>
                                    </button>
                                </div>
                                { this.state.isAdmin &&
                                    <div className="col s12 m6" style={{marginTop : 15}}>
                                        <Link to="register-details" onClick={this.onCreateNewRegister}>
                                            <button className="btn waves-effect waves-light right" type="button" name="addBtn" style={{ marginLeft : 0, height: 50}} >
                                                {lblSrvc.getLabel(this.props.selectedLanguage, 'ADD_REGISTER')}
                                                <i className="material-icons left">add</i>
                                            </button>
                                        </Link>
                                    </div>
                                }
                            </div>
                            <div className="hide-on-small-only">
                                <div className="right">
                                    <div className="input-col">
                                        <img src={require("./exportExcel.png")} width="32" style={{ cursor: 'pointer'}} onClick={() => this.exportExcel()} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <span className="filter-icon"><i className="fa fa-filter"></i></span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, { getSearchedRegisters, getNumberOfRegisters, selectRegister })(SearchForm);