import React, { Component } from 'react'
import ParallaxImage from './ParallaxImage.js'
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import lblSrvc from '../../../labelService';

export class ImageSection extends Component {
    render() {
        return (
            <div className="scc-image-section center-align" style={{position : 'relative', height : 500}}>
                <ParallaxImage />
                <div className="scc-image-section-content animated-header centered-container-column">
                    <div className="scc-image-section-title">
                            <div className="mainText"> {lblSrvc.getLabel(this.props.selectedLanguage, 'MAIN_TITLE')}</div>
                            <div className="mainTextSmall">{lblSrvc.getLabel(this.props.selectedLanguage, 'MAIN_SUB_TITLE')}</div>
                    </div>
                    <div className="scc-image-section-button-container">
                        <Link to="/search-page" className="scc-image-section-button">
                            <span className="pretragaText">{lblSrvc.getLabel(this.props.selectedLanguage, 'MAIN_BUTTON_LABEL')}</span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {  })(ImageSection);
