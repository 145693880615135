import React, { Component } from 'react'
import  { connect } from 'react-redux';
import  lblSrvc from '../../labelService';
import { userConstants } from "../../_constants/userConstants";
import axios from "axios";
import Header  from '../Header/Header';
import "react-datepicker/dist/react-datepicker.css";
import Footer from '../Footer/Footer';
import CommentsSection from './CommentsSection';
import { Redirect } from 'react-router-dom';

class Comments extends Component {

    constructor(props){
        super(props);

    }

    closeComments(){
     
    }

    render() {
        return (
            <div className="scc-min-height">
                <Header  />
                <div className="scc-page-container ">
                    <div  style={{ backgroundImage: `url(${require('./guide.png')})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
                        <div className="scc-banner-container"  style={{ backgroundColor : 'rgba(47, 43, 43, 0.7)'}} >
                            <div className="centered-container" style={{padding: 25, height: '100%'}}>
                                <div className="scc-banner-content">
                                    <div className="scc-banner-title">
                                        <h5>
                                            {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_BANNER_SEARCH_TITLE_FIRST')}
                                        </h5>
                                        <h5>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_BANNER_SEARCH_TITLE_SECOND')}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="scc-page-content ">
                        <div className="scc-search-section ">
                            <div className="container" style={{marginTop: 60}}>
                                <div className="table-wrapper scc-min-height">
                                    <div className="table-title">
                                        <div className="row" style={{ padding: 17, justifyContent : 'space-between'}}>
                                            <div  className="col s12 m6 scc-header-title" >
                                                <h2>{lblSrvc.getLabel(this.props.selectedLanguage, 'SEARCH_PAGE_COMMENTS_TITLE')}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <CommentsSection loggedIn={this.props.loggedIn} closeComments={this.closeComments} register_id={this.props.selectedRegister} selectedLanguage={this.props.selectedLanguage}  />
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    const selectedRegister = state.registers.selectedRegister;

    return {
        loggedIn, user, selectedLanguage, selectedRegister
    };
}
const connectedComments = (connect(mapStateToProps)(Comments));
export {connectedComments as Comments};

