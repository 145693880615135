import React, { PureComponent, Component } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Naziv } from 'recharts';
import { connect } from 'react-redux';
import lblSrvc from '../../../labelService';
import axios from "axios";

export class KoefKonChart extends Component {
    constructor(props) {
        super(props);

        this.state = {opstine : []};
    }

    componentDidMount(){
        //check if is it admin 
        this.loadData();
    }

    loadData() {
        axios.get("/api/konk_opstine",  {  })
            .then(res => {
                this.setState({ opstine: res.data});
                console.log(res.data);
            })
            .catch((error) => {                
        });
    }

    /* <ResponsiveContainer width="100%" height="100%" className="frame">
		        <BarChart
		          layout="horizontal"
		          width={500}
		          height={300}
		          data={this.state.opstine}
		          margin={{
		            top: 5,
		            right: 30,
		            left: 20,
		            bottom: 65,
		          }}
		        >
		          <CartesianGrid strokeDasharray="3 3" />
		          <XAxis dataKey="Naziv" angle={-90} textAnchor="end"/>
		          <YAxis domain={[0, 1]} textAnchor="middle" verticalAnchor="middle"/>
		          <Tooltip />		         
		          <Bar dataKey="Koeficijent konkurentnosti"  barSize={20} label={{position: 'top' }} fill="#8884d8" >
		          
		          </Bar>
		        </BarChart>
		      </ResponsiveContainer>*/
	barColors = ["#1f77b4", "#ff7f0e", "#4db6ac"];

	calculateBarColor = (entry) => {
		console.log(entry["Koeficijent konkurentnosti"]);
		if (entry["Koeficijent konkurentnosti"] === null) return this.barColors[0];
		if (entry["Koeficijent konkurentnosti"] < 0.3) return this.barColors[1];
		if (entry["Koeficijent konkurentnosti"] < 0.6) return this.barColors[0];
		
		return this.barColors[2];

	}

    render() {

        return (
        	<div style={{ width: '100%', height: 600 }}>
                
            <ResponsiveContainer width="100%" height="100%" className="">
		        <BarChart
		          layout="vertical"
		          width={500}
		          height={300}
		          data={this.state.opstine}
		          margin={{
		            top: 65,
		            right: 65,
		            left: 65,
		            bottom: 65,
		          }}
		        >
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis type="number" hide/>
					<YAxis dataKey="Naziv" type="category" width={150} padding={{ left: 20 }} verticalAnchor="middle" interval={0}/>
					<Tooltip />		         
					<Bar dataKey="Koeficijent konkurentnosti"  barSize={20} label={{ fill: 'black', fontSize: 16 }}  >
					{
                        this.state.opstine.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={this.calculateBarColor(entry)} />
                        ))
                    }
					</Bar>
		        </BarChart>
		      </ResponsiveContainer>
		      </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {  })(KoefKonChart);