import { languages } from './../languages';
import { SEARCH} from '../actions/types'

let initState = { data : [],searchResults : [], count : 0, isLoading : false, reg_count: 0};

export default (state = initState, action) => {
    
    switch(action.type) {
      case SEARCH:
        return {...state, data : action.registers, count : action.registersCount, searchedData : action.searchedData };
      case 'REGISTERS_COUNT' :
        return {...state, count : action.registersCount};
      case 'REGISTERS_SEARCH' :
          return {...state, searchedData : action.searchedData};
      case 'LOADING' :
          return {...state, isLoading : action.isLoading};
      case 'SELECT_REGISTER' :
          return {...state, selectedRegister : action.selectedRegister};
      case 'REG_COUNT' :
        return {...state, reg_count : action.reg_count};
      default: 
        return state;
    }
};

