import React, { Component } from 'react';
import $ from 'jquery'; 
import  { connect } from 'react-redux';
import  lblSrvc from '../../labelService';
import { userConstants } from "../../_constants/userConstants";
import axios from "axios";
import Header  from '../Header/Header';
import Select from 'react-select';
import Spiner from '../Spiner/Spiner'
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from '../Footer/Footer';
import MySelect from '../MultiSelect/MySelect';

import sr from 'date-fns/locale/sr-Latn';
import moment from 'moment';
registerLocale('sr-Latn', sr);

const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '45px',
      height: '45px',
      boxShadow: state.isFocused ? null : null,
      border: '1px solid #26a69a !important'
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '45px',
      padding: '0 6px',
      'flex-direction': 'column',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        marginTop: '10px',
        backgroundColor: '#2ad2c2',
        color: 'white',
    }),
    placeholder: (provided, state) => ({
        display: 'none'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    menu: (provided, state) => ({
        ...provided,
        'background-color' : 'white',
        'z-index': '100000',
      }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '50px',
    }),
    option: (provided, { isSelected }) => {
        if(isSelected){
            return { 
                ...provided,
                backgroundColor: '#26a69a',
            };
        }
        return {
            ...provided,
        }
      },
};

class RegisterDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codes : { locations: [], types: [], sectors: [], frequency: [], paymentType: [], taxPayment: [], areaLvl1: [], areaLvl2 : [], procedure: [], state: [], paymentObligors: []},
            isAdmin : false,
            name : null, //Naziv
            sector : null, //SektorId
            prescription : null,
            location : null, //OpstinaId
            state : null, //Status
            use : null,
            price_from : null, //IznosOd
            price_to : null, //IznosDo
            type : null, // VrstaId
            record_bill : null,
            selectedLocation: [],
            bank_account : null, //ZiroRacun
            scriptRegister: null, //PropisNamet
            scriptAmount: null, //PropisIznos
            groupName: null, 
            institution: null, //Institucija
            dateBegin: null, //DatumPocetka
            dateUpdate:  null, //DatumUpdate
            frequencyPayment: null, //FrekvencijaPlacanjaId
            procedure: null, //ProceduraId
            exceptions: null, //Izuzeci
            paymentType: null, //NacinPlacanjaId
            taxPayment: null, //TaksaPlacanjaId
            paymentObligor: null, //ObveznikPlacanja
            paymentObligorType: null, //VrstaObveznikaId
            base: null, //Osnovica
            amount: null, //Iznos
            codeD: null,
            codeN: null,
            areaLvl1: null,
            areaLvl2 : null,
            register_code: null,
            nameByGroup: null, //GrupisaniNaziv
            loading: false,
            isEdit: false,
            //error reporting 
            err_name : false, 
            err_name_msg : '',
            err_scriptRegister: false, 
            err_scriptRegister_msg : '',
            err_group: false, 
            err_group_msg : '',
            err_base: false, 
            err_base_msg : '',
            err_amount: false, 
            err_amount_msg : '',
            err_type: false, 
            err_type_msg : '',
            err_dateBegin: false, 
            err_dateBegin_msg : '',
            err_register_code: false,
            err_register_code_msg: '',
            startDate: new Date(),
            err_areaLvl1: false, 
            err_areaLvl1_msg : '',
            err_areaLvl2: false, 
            err_areaLvl2_msg : '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getAllCode = this.getAllCode.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0, 0);

        //check if is it admin 
        this.loadUser();
        const M = window.M;

        M.AutoInit();
        if (this.props.selectedRegister && this.props.selectedRegister > 0){
            this.setState({ isEdit : true});
            this.getRegisterById();
        }
        else 
            this.getAllCode();

        setTimeout(() => {
            M.textareaAutoResize($('#groupName'));
            M.textareaAutoResize($('#scriptAmount'));
        }, 1000);
    }

    loadUser() {
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data) {
            //this.props.history.push('/main');
        }else if(data.isAdmin){
            this.setState({ isAdmin: true});
        }
    }

    handleChange = date => {
        //console.log(date);
        this.setState({
          dateBegin: date
        });
    }

    getSelectObject = (data, value) => {
        let obj = { value : 0, label: ''};

        for (let i = 0; i < data.length; i++) {
            if(data[i].value === value) 
                obj = data[i];
        }
        return [obj];
    }

    async getAllCode(){
        await this.setState({ loading: true });

        axios.get("/api/getAllCode",  {  })
            .then(res => {
                this.setState({ codes: res.data});
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
        });
    }

    async getRegisterById(){
        const M = window.M;

        await this.setState({ loading: true });

        axios.get("/api/getRegisterById", {params: {id : this.props.selectedRegister }} )
            .then(res => {
                this.setState({loading : false});
                
                this.setState({
                    name: res.data.Naziv, //Naziv
                    nameEng: res.data.Name,
                    sector: res.data.SektorId, //SektorId
                    location: res.data.OpstinaId, //OpstinaId
                    state: res.data.StatusId, //Status
                    price_from: res.data.IznosOd, //IznosOd
                    price_to: res.data.IznosDo, //IznosDo
                    type: res.data.VrstaId, // VrstaId
                    bank_account: res.data.ZiroRacun, //ZiroRacun
                    scriptRegister: res.data.PropisNamet, //PropisNamet
                    scriptAmount: res.data.PropisIznos, //PropisIznos
                    groupName: res.data.RazvrstaniNaziv, //RazvrstaniNaziv
                    nameByGroup: res.data.GrupisaniNaziv, //RazvrstaniNaziv
                    institution: res.data.Institucija, //Institucija
                    dateBegin: res.data.DatumPocetka, //DatumPocetka
                    dateUpdate:  res.data.DatumUpdate, //DatumUpdate
                    frequencyPayment: res.data.FrekvencijaPlacanjaId, //FrekvencijaPlacanjaId
                    exceptions: res.data.Izuzeci, //Izuzeci
                    paymentType: res.data.NacinPlacanjaId, //NacinPlacanjaId
                    taxPayment: res.data.TaksaPlacanjaId, //TaksaPlacanjaId
                    paymentObligor: res.data.ObveznikPlacanja, //ObveznikPlacanja
                    paymentObligorType: res.data.VrstaObaveznikaId, //VrstaObaveznikaId
                    base: res.data.Osnovica, //Osnovica
                    amount: res.data.Iznos, //Iznos
                    codeD: res.data.SifraD,
                    codeN: res.data.SifraN,
                    areaLvl1: res.data.OblastNivo1_Id,
                    areaLvl2: res.data.OblastNivo2_Id,
                    price_pre_from: res.data.IznosProcenatOd,
                    price_pre_to: res.data.IznosProcenatDo,
                    procedure: res.data.ProceduraId,
                    register_code: res.data.rb
                });
                
                setTimeout(() => {
                    M.updateTextFields();
                }, 300);

                this.getAllCode();
            })
            .catch((error) => {
                this.setState({loading : false});
            }
        );
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value });
    }

    handleSelectChange = event => {
        this.setState({ [event.target.name] : event.target.value});
    }

    generateOptions = (data) => {
        if(data == undefined) return [];
        return data.map( option => {
            return (<option value={option.value}> {option.label} </option>);
        });
    }

    validateField = async (toastTimeout) => {
        const M = window.M; 
        
        if (!this.state.register_code) {
            await this.setState({err_register_code : true, err_register_code_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_NUMBER')});
            M.toast({html: this.state.err_register_code_msg, displayLength : toastTimeout});
            return true;
        }

        this.setState({err_register_code : false});

        if (!this.state.name) {
            await this.setState({err_name : true, err_name_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME')});
            M.toast({html: this.state.err_name_msg, displayLength : toastTimeout});
            return true;
        }

        this.setState({err_name : false});
        
        if(!this.state.scriptRegister){
            
            await this.setState({err_scriptRegister : true, err_scriptRegister_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_SCRIPT')});
            M.toast({html: this.state.err_scriptRegister_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_scriptRegister : false});
        
       
        if(!this.state.groupName){
            await this.setState({err_group : true, err_group_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SORTED_NAME')});
            M.toast({html: this.state.err_group_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_group : false});

        /*if(!this.state.base){
            
            await this.setState({err_base : true, err_base_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_BASE')});
            M.toast({html: this.state.err_base_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_base : false});*/

        if(!this.state.areaLvl1){
            
            await this.setState({err_areaLvl1 : true, err_areaLvl1_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AREA1')});
            M.toast({html: this.state.err_areaLvl1_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_areaLvl1 : false});

        if(!this.state.areaLvl2){
            
            await this.setState({err_areaLvl2 : true, err_areaLvl2_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AREA2')});
            M.toast({html: this.state.err_areaLvl2_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_areaLvl2 : false});

        if(!this.state.amount){
            
            await this.setState({err_amount : true, err_amount_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AMOUNT')});
            M.toast({html: this.state.err_amount_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_amount : false});

        if(!this.state.dateBegin){
            
            await this.setState({err_dateBegin: true, err_dateBegin_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_DATE')});
            M.toast({html: this.state.err_dateBegin_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_dateBegin : false});

        if(!this.state.type){
            
            await this.setState({err_type : true, err_type_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TYPE')});
            M.toast({html: this.state.err_type_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_type : false});
        
        return false;
    }

    onClose = () => {
        this.props.history.push("/search-page");
    }

    savedata = async () => {
        let toastTimeout = 3000;
        const M = window.M;

        try {
            let validate = false;
            await this.validateField(toastTimeout).then((result) => {
                validate = result;
            });
            
            if(validate) return;

            await this.setState({
                err_name : false,
                err_name_msg : '',
                err_scriptRegister: false, 
                err_scriptRegister_msg : '',
                err_group: false, 
                err_group_msg : '',
                err_base: false, 
                err_base_msg : '',
                err_amount: false, 
                err_amount_msg : '',
                err_type: false, 
                err_type_msg : '',
                err_dateBegin: false, 
                err_dateBegin_msg : '',
                err_register_code: false,
                err_register_code_msg: '',
                err_areaLvl1: false, 
                err_areaLvl1_msg : '',
                err_areaLvl2: false, 
                err_areaLvl2_msg : '',
            });
            //ispitai ostala polja na slican nacin koja trebaju 
            
            let form_data = {
                id: this.props.selectedRegister,
                newRegisterId: 11,
                name: this.state.name, //Naziv
                nameEng: this.state.nameEng,
                sector: this.state.sector, //SektorId
                location: this.state.location, //OpstinaId
                state: this.state.state, //Status
                price_from: this.state.price_from, //IznosOd
                price_to: this.state.price_to, //IznosDo
                type: this.state.type, // VrstaId
                bank_account: this.state.bank_account, //ZiroRacun
                scriptRegister: this.state.scriptRegister, //PropisNamet
                scriptAmount: this.state.scriptAmount, //PropisIznos
                groupName: this.state.groupName, //RazvrstaniNaziv
                nameByGroup : this.state.nameByGroup, //GrupisaniNaziv
                institution: this.state.institution, //Institucija
                dateBegin: this.state.dateBegin, //DatumPocetka
                frequencyPayment: this.state.frequencyPayment, //FrekvencijaPlacanjaId
                exceptions: this.state.exceptions, //Izuzeci
                paymentType: this.state.paymentType, //NacinPlacanjaId
                taxPayment: this.state.taxPayment, //TaksaPlacanjaId
                paymentObligor: this.state.paymentObligor, //ObveznikPlacanja
                paymentObligorType: this.state.paymentObligorType, //ObveznikPlacanja
                base: this.state.base, //Osnovica
                amount: this.state.amount, //Iznos
                codeD: this.state.codeD,
                codeN: this.state.codeN,
                areaLvl1: this.state.areaLvl1,
                areaLvl2: this.state.areaLvl2,
                price_pre_from: this.state.price_pre_from,
                price_pre_to: this.state.price_pre_to,
                procedure: this.state.procedure,
                register_code: this.state.register_code
            };
            
            try {
                form_data.dateBegin = moment(form_data.dateBegin).format('YYYY-MM-DD');
            }
            catch(e){}

            console.log('form_data', form_data);

            await this.setState({ loading: true });
            if(this.state.isEdit){
                //update register
                axios.post("/api/updateRegister", {params: { form_data : form_data }} )
                    .then(res => {                        
                        if (res  && res.data && res.data.msg == 'Uspesno'){
                            this.setState({loading : false});
                            this.props.history.push('/search-page');
                            M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_SUCCESS'), displayLength : toastTimeout});                            
                        }
                        else{
                            this.setState({loading : false});
                            M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_ERROR'), displayLength : toastTimeout});
                        }
                    })
                    .catch((error) => {
                        this.setState({loading : false});
                        M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_ERROR'), displayLength : toastTimeout});
                    }
                );
            }else {
                //add new register
                axios.post("/api/addRegister", {params: { form_data : form_data }} )
                    .then(res => {
                        //console.log('res', res);
                        if (res  && res.data && res.data.msg == 'Uspesno'){
                            this.setState({loading : false});
                            this.props.history.push('/search-page');
                            M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_SUCCESS'), displayLength : toastTimeout});                            
                        }
                        else{
                            this.setState({loading : false});
                            M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_ERROR'), displayLength : toastTimeout});
                        }
                    })
                    .catch((error) => {
                        this.setState({loading : false});
                        M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_ERROR'), displayLength : toastTimeout});
                    }
                );
            }
        }
        catch(err){}
    }

    getTextAreaHeight(groupName){
        if(!groupName) return 30;
        else return 105;
    }

    getSaveBtnText(){
        if(this.state.isEdit) return lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE');
        else return lblSrvc.getLabel(this.props.selectedLanguage, 'ADD');
    }

    getTitle(){
        if(!this.state.isAdmin) return lblSrvc.getLabel(this.props.selectedLanguage, 'USER_REGISTER_TITLE');
        else if(this.state.isEdit) return lblSrvc.getLabel(this.props.selectedLanguage, 'ADMIN_REGISTER_TITLE');
        else return lblSrvc.getLabel(this.props.selectedLanguage, 'ADMIN_ADD_REGISTER_TITLE');
    }

    getValidDate = (date) => {
        if(moment(date).isValid()){
            let text = moment(date).format('MM-DD-YYYY');
            return new Date(text);
        }else {
            return '';
        }
    }

    getAreaLevel2(){
        let {areaLvl1} = this.state;
        let areaLvl2 = this.state.codes.areaLvl2;

        if(areaLvl2 == undefined) return [];
        let newAreaLvl2 = [];

        for (let i = 0; i < areaLvl2.length; i++) {
            if(areaLvl2[i].arealvl1id === parseInt(areaLvl1)){
                newAreaLvl2.push(areaLvl2[i]);
            }
        }

        return newAreaLvl2;
    }

    render() {
        let {locations} = this.state.codes;
        let areaLvl2 = this.getAreaLevel2();   
        let saveBtn = this.getSaveBtnText();
        let title = this.getTitle();

        return (
            <div className="scc-min-height">
                {this.state.loading &&
                    <Spiner></Spiner>
                }
                <Header />
                <div className="scc-page-container">
                    <div className="container" style={{marginTop : 70}}>
                        <div className="row centered-container">
                            <h4>{title}</h4>
                        </div>
                        <div className="row">
                            <div className="col-sm-9" style={{ margin: 25}}>
                                <form>
                                    <div className="col s12 m6">
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="register_code" name="register_code" type="number" className="validate" value={this.state.register_code} onChange={this.handleInputChange} />
                                            <label for="register_code">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_NUMBER')+'*'}</label>
                                            {this.state.err_register_code &&
                                                <div className="lbl-err-txt">{this.state.err_register_code_msg}</div>
                                            }
                                        </div>
                                        {this.state.isAdmin && <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_STATE')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={this.state.codes.state}
                                                value={this.getSelectObject(this.state.codes.state, this.state.state)}
                                                onChange={selected => this.setState({state : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>}
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="name" name="name" type="text" className="validate" value={this.state.name} onChange={this.handleInputChange} />
                                            <label for="name">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME')+'*'}</label>
                                            {this.state.err_name &&
                                                <div className="lbl-err-txt">{this.state.err_name_msg}</div>
                                            }
                                        </div>
                                        <div className="input-field col s12">
                                            <textarea disabled={!this.state.isAdmin} id="groupName" name="groupName" type="text" className="validate materialize-textarea" value={this.state.groupName} onChange={this.handleInputChange}></textarea >
                                            <label for="groupName">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SORTED_NAME')+'*'}</label>
                                            {this.state.err_group &&
                                                <div className="lbl-err-txt">{this.state.err_group_msg}</div>
                                            }
                                        </div>

                                        <div className="input-field col s12">
                                            <textarea disabled={!this.state.isAdmin} id="nameByGroup" name="nameByGroup" type="text" className="validate materialize-textarea" value={this.state.nameByGroup} onChange={this.handleInputChange}></textarea >
                                            <label for="nameByGroup">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_GROUP_NAME')+''}</label>
                                        </div>

                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_LOCATION')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={locations }
                                                value={this.getSelectObject(locations, this.state.location)}
                                                onChange={selectedLocation => this.setState({location : selectedLocation.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TYPE')+'*'}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={this.state.codes.types}
                                                value={this.getSelectObject(this.state.codes.types, this.state.type)}
                                                onChange={selected => this.setState({type : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                            {this.state.err_type &&
                                                <div className="lbl-err-txt">{this.state.err_type_msg}</div>
                                            }
                                        </div>

                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}   id="scriptRegister" name="scriptRegister" type="text" className="validate" value={this.state.scriptRegister} onChange={this.handleInputChange}></input>
                                            <label for="scriptRegister"> {lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_SCRIPT')+'*'}</label>
                                            {this.state.err_scriptRegister &&
                                                <div className="lbl-err-txt">{this.state.err_scriptRegister_msg}</div>
                                            }
                                        </div>
                                        <div className="input-field col s12">
                                            <textarea disabled={!this.state.isAdmin} id="scriptAmount" name="scriptAmount" type="text" className="validate materialize-textarea" value={this.state.scriptAmount} onChange={this.handleInputChange}></textarea>
                                            <label for="scriptAmount">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SCRIPT_AMOUNT')}</label>
                                        </div>

                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AREA1')+'*'}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={this.state.codes.areaLvl1}
                                                value={this.getSelectObject(this.state.codes.areaLvl1, this.state.areaLvl1)}
                                                onChange={selected => this.setState({areaLvl1 : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                            {this.state.err_areaLvl1 &&
                                                    <div className="lbl-err-txt">{this.state.err_areaLvl1_msg}</div>
                                            }
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AREA2')+'*'}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={areaLvl2}
                                                value={this.getSelectObject(areaLvl2, this.state.areaLvl2)}
                                                onChange={selected => this.setState({areaLvl2 : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                            {this.state.err_areaLvl2 &&
                                                    <div className="lbl-err-txt">{this.state.err_areaLvl2_msg}</div>
                                            }
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_DATE')+'*'}</span>
                                            <DatePicker
                                                selected={this.getValidDate(this.state.dateBegin)}
                                                disabled={!this.state.isAdmin}
                                                onChange={this.handleChange}
                                                placeholderText={this.props.selectedLanguage == 'ENG' ? 'MM/DD/YYYY' : 'MM.DD.GGGG'}
                                                locale={this.props.selectedLanguage == 'ENG' ? 'en' : 'sr-Latn'} 
                                                dateFormat="dd.MM.yyyy"
                                            />
                                            {this.state.err_dateBegin &&
                                                <div className="lbl-err-txt">{this.state.err_dateBegin_msg}</div>
                                            }
                                        </div>
                                        <div className="input-field col s12">
                                            <input disabled={true}  name="dateUpdate" type="text" className="validate" value={this.state.dateUpdate ? moment(this.state.dateUpdate).format('DD.MM.YYYY') : 'DD.MM.GGGG'} ></input>
                                            <label for="dateUpdate">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_DATE_UPDATE')}</label>
                                        </div>
                                    </div>
                                    <div className="col s12 m6">
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="amount" name="amount" type="text" className="validate" value={this.state.amount} onChange={this.handleInputChange}></input>
                                            <label for="amount">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AMOUNT')+'*'}</label>
                                            {this.state.err_amount &&
                                                <div className="lbl-err-txt">{this.state.err_amount_msg}</div>
                                            }
                                        </div>
                                        {this.state.isAdmin && 
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="base" name="base" type="text" className="validate" value={this.state.base} onChange={this.handleInputChange}></input>
                                            <label for="base">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_BASE')}</label>
                                            {this.state.err_base &&
                                                <div className="lbl-err-txt">{this.state.err_base_msg}</div>
                                            }
                                        </div>}
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_FREQUENCY')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={this.state.codes.frequency}
                                                value={this.getSelectObject(this.state.codes.frequency, this.state.frequencyPayment)}
                                                onChange={selected => this.setState({frequencyPayment : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}    id="nameEng" name="nameEng" type="text" className="validate" value={this.state.nameEng} onChange={this.handleInputChange} />
                                            <label for="nameEng">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME_ENG')}</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}   id="paymentObligor" name="paymentObligor" type="text" className="validate" value={this.state.paymentObligor} onChange={this.handleInputChange}></input>
                                            <label for="paymentObligor">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_OBLIGATOR')}</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_OBLIGATOR_TYPE')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={this.state.codes.paymentObligors }
                                                value={this.getSelectObject(this.state.codes.paymentObligors, this.state.paymentObligorType)}
                                                onChange={selectedLocation => this.setState({paymentObligorType : selectedLocation.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}   id="institution" name="institution" type="text" className="validate" value={this.state.institution} onChange={this.handleInputChange}></input>
                                            <label for="institution">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_INSTITUTION')}</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}   id="bank_account" name="bank_account" type="text" className="validate" value={this.state.bank_account} onChange={this.handleInputChange}></input>
                                            <label for="bank_account">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_BANK_ACC')}</label>
                                        </div>

                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_PAYMENT_TYPE')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={this.state.codes.paymentType}
                                                value={this.getSelectObject(this.state.codes.paymentType, this.state.paymentType)}
                                                onChange={selected => this.setState({paymentType : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TAX')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={this.state.codes.taxPayment}
                                                value={this.getSelectObject(this.state.codes.taxPayment, this.state.taxPayment)}
                                                onChange={selected => this.setState({taxPayment : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_PROCEDURE')}</span>
                                            <MySelect 
                                                maxHeight={30}
                                                options={this.state.codes.procedure}
                                                value={this.getSelectObject(this.state.codes.procedure, this.state.procedure)}
                                                onChange={selected => this.setState({procedure : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        {this.state.isAdmin && 
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="price_from" name="price_from" type="number" className="validate" value={this.state.price_from} onChange={this.handleInputChange}></input>
                                            <label for="price_from">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_COST_FROM')}</label>
                                        </div>}
                                        {this.state.isAdmin && 
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="price_to" name="price_to" type="number" className="validate" value={this.state.price_to} onChange={this.handleInputChange}></input>
                                            <label for="price_to">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_COST_TO')}</label>
                                        </div>}
                                        {this.state.isAdmin && 
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="price_pre_from" name="price_pre_from" type="number" className="validate" value={this.state.price_pre_from} onChange={this.handleInputChange}></input>
                                            <label for="price_pre_from">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_COST_PRE_FROM')}</label>
                                        </div>}
                                        {this.state.isAdmin && 
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="price_pre_to" name="price_pre_to" type="number" className="validate" value={this.state.price_pre_to} onChange={this.handleInputChange}></input>
                                            <label for="price_pre_to">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_COST_PRE_TO')}</label>
                                        </div>}
                                        
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="exceptions" name="exceptions" type="text" className="validate" value={this.state.exceptions} onChange={this.handleInputChange}></input>
                                            <label for="exceptions">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_EXCEPTIONS')}</label>
                                        </div>
                                       
                                        {this.state.isAdmin &&
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}   id="codeD" name="codeD" type="text" className="validate" value={this.state.codeD} onChange={this.handleInputChange}></input>
                                            <label for="codeD">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_CODE_D')}</label>
                                        </div>}
                                        {this.state.isAdmin &&
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}   id="codeN" name="codeN" type="text" className="validate" value={this.state.codeN} onChange={this.handleInputChange}></input>
                                            <label for="codeN">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_CODE_N')}</label>
                                        </div>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.isAdmin ?
                        <div className="row right-container" style={{paddingRight: 44}}>
                            <button className="btn waves-effect waves-light" type="button" name="searchBtn" style={{ marginRight : 30}} onClick={this.savedata}>
                                {saveBtn}
                            </button>
                          
                            <button className="btn waves-effect waves-light red lighten-3" type="button" name="searchBtn" style={{ marginLeft : 0}} onClick={this.onClose}>
                                {lblSrvc.getLabel(this.props.selectedLanguage, 'CANCEL')}
                            </button>
                        </div>
                        :  
                        <div className="row right-container" style={{paddingRight: 44}}>
                            <button className="btn waves-effect waves-light" type="button" name="back" style={{marginTop: 20, paddingLeft : 15}} onClick={this.onClose}>
                                {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_COMMENT_BACK')}
                                <i className="material-icons left" style={{fontSize : '1.6rem'}}>keyboard_backspace</i>
                            </button>
                        </div>
                  
                        }
                    </div>
                </div>
            </div>

        )
    }
}

/*const mapStateToProps = state => ({
    selectedLanguage: state.language,
    selectedRegister: state.registers.selectedRegister,

    return {
        selectedRegister, selectedLanguage
    };
});*/

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    const selectedRegister = state.registers.selectedRegister;

    return {
        loggedIn, user, selectedLanguage, selectedRegister
    };
}

const connectedRegisterDetails = (connect(mapStateToProps)(RegisterDetails));
export {connectedRegisterDetails as RegisterDetails};

//export default connect(mapStateToProps, { })(RegisterDetails);
