import React, { Component } from 'react';
import TableRow from './TableRow';
import { connect } from 'react-redux';
import lblSrvc from '../../../labelService';

export class DataTable extends Component {

    constructor(props){
        super(props);
    }

    generateTableHeaderColumns(){
        return (
            <tr className="scc-table-header-text">
                {this.props.isAdmin ? <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'EDIT')}</th> : <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'READ_MORE')}</th> }
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'SEARCH_PAGE_COMMENTS_TITLE')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_NUMBER')}</th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME_ENG')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SORTED_NAME')} </th>   
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_GROUP_NAME')} </th>               
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_LOCATION')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TYPE')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_SCRIPT')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_SCRIPT_AMOUNT')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AREA1')}</th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AREA2')}</th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_DATE')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AMOUNT')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_BASE')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_BANK_ACC')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_PAYMENT_TYPE')}</th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TAX')}</th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_FREQUENCY')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_INSTITUTION')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_OBLIGATOR')}</th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_OBLIGATOR_TYPE')}</th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_EXCEPTIONS')}</th>
            </tr>
        );
    }

    generateRows = () => {
        if(this.props.data == undefined) return null;
        
        return this.props.data.map((register, index) => (
            <TableRow data={register} key={index} id={index} isAdmin={this.props.isAdmin}/>
        ));
    }

    render() {
        return (
            <div style={{overflowX : 'scroll'}}>
                <table className="table table-striped table-hover">
                    <thead>
                       {this.generateTableHeaderColumns()}
                    </thead>
                    <tbody>
                       {this.generateRows()}
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {   })(DataTable);