
//main component for rendering timeline 
//this component consists of several subcomponents
import React, { useEffect, Fragment } from 'react';
import  Main  from './components/Main/main.js';
import  SearchPage  from './components/SearchPage/SearchPage';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Provider} from 'react-redux';
import store from './store.js';
import {Login} from './components/Login/Login';
import {RegisterDetails} from './components/Admin/RegisterDetails';
import {Reports} from './components/Reports/Reports';
import {Comments} from './components/Comments/Comments.js';
import Footer from './components/Footer/Footer';
import { LicenceDetails } from './components/Admin/LicenceDetails.js';

export default class App extends React.Component {

    constructor(props) {
        super(props);  
    }
   
    componentDidMount() {
       // httpService.setHistory(this.props.history);
        //this.props.history.push("/main");
    }

    componentDidUpdate(){
    }

    componentWillUnmount() {
    }

    render() {

        return (
            <Provider store={store}>
                <Router>
                    <Fragment>
                        <div className="scc-container">
                            <Switch>
                                <Route path="/" exact component={Main}/>
                                <Route path="/search-page"  component={SearchPage}/>
                                <Route path="/login" component={Login} />
                                <Route path="/register-details" component={RegisterDetails} />
                                {/*<Route path="/licence-details" component={LicenceDetails} />*/}
                                <Route path="/reports" component={Reports} />
                                <Route path="/comments" component={Comments} />
                            </Switch>
                        </div>
                        <Footer/>
                    </Fragment>
                </Router>
            </Provider>
    	);
    }
}

