import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import  { connect } from 'react-redux';
import  { selectRegister } from '../../../actions/RegisterActions';
import ReactTooltip from 'react-tooltip';
//import moment from 'moment';

export class TableRow extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {

    }

    handleClick = () => {
        this.props.selectRegister(this.props.data.id);
    }

    onEditRegister = () => {
        this.props.selectRegister(this.props.data.id);
    }

    getValidValue(value){
        if(value == null || value == undefined) return '';
        value = value.toString()
        if(value.length > 35){
            value = value.substring(0,35) + '...';
        }

        return value;
    }

    getCustomsLink(row){
        //console.log(row);
        
        if (row.type == 'Carine' && row.amount){
            let indx = row.amount.indexOf('http://');
            
            if (indx !== -1){
                let link = row.amount.substring(indx);
                console.log(indx);
                return (<a href={link} target = "_blank">{link}</a>);                
            }
        }

        return (<div>{row.amount}</div>);
    }

    render() {
        let register = this.props.data;
        let dateBegin = register.dateBegin;
        let dateUpdate = register.dateUpdate;

        return (
            <tr>
                <th className="scc-table-cell">
                    <Link onClick={this.onEditRegister} to="/register-details" className="material-icons scc-table-edit-btn waves-effect waves-light">
                        { this.props.isAdmin ? 'create' : 'read_more'}
                    </Link>
                </th>
                <td className="scc-table-cell">
                    <Link to="/comments" onClick={this.handleClick}>
                        <i className="material-icons scc-table-edit-btn waves-effect waves-light">comment</i>
                    </Link>
                </td>
                <td className="scc-table-cell">{register.code}</td>
                <td className="scc-table-cell">{register.name}</td>
                <td className="scc-table-cell">{register.nameEng}</td>
                <td className="scc-table-cell">
                    <div className="tooltipped" data-tip={register.groupName} data-for='groupNameTooltip'>{this.getValidValue(register.groupName)}</div>
                    <ReactTooltip id='groupNameTooltip' type='' effect='solid'    >
                    </ReactTooltip>
                </td>
                <td className="scc-table-cell">{register.nameByGroup}</td>
                <td className="scc-table-cell">{this.getValidValue(register.location_name)} </td>                        
                <td className="scc-table-cell">{this.getValidValue(register.type)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.scriptRegister)} </td>
                <td className="scc-table-cell">
                    <div className="tooltipped" data-tip={register.scriptAmount} data-for='scriptAmountTooltip'>{this.getValidValue(register.scriptAmount)}</div>
                    <ReactTooltip id='scriptAmountTooltip' type='' effect='solid' />
                </td>
                <td className="scc-table-cell">{this.getValidValue(register.areaLvl1)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.areaLvl2)} </td>
                <td className="scc-table-cell"> {dateBegin}</td>
                <td className="scc-table-cell">{this.getCustomsLink(register)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.base)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.bank_account)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.paymentType)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.taxPayment)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.frequency)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.institution)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.obligator)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.paymentObligatorType)} </td>
                <td className="scc-table-cell">{this.getValidValue(register.exceptions)} </td>

                
            </tr>
        )
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { selectRegister })(TableRow);