import React, { Component } from 'react'
import  { connect } from 'react-redux';
import  lblSrvc from '../../../labelService';
import  { getSearchedLicences, loadingStatus, selectLicence } from '../../../actions/licenseActions';
import { userConstants } from "../../../_constants/userConstants";
import axios from "axios";
import { registerLocale } from  "react-datepicker";
import sr from 'date-fns/locale/sr-Latn';
import DataTable from './DataTable/dataTable';
registerLocale('sr-Latn', sr);

export class LicenseSearchForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            startBtn: 0,
            currentTablePage : 0,
            loggedIn: false,
            isAdmin: false
        };
    }

    componentDidMount(){
        this.loadUser();
    }

    componentDidUpdate(props){
    }

    loadUser() {
       
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data ) return;
        
        if(data.isAdmin){
            this.setState({ isAdmin: true, loggedIn: true});
        }else if(data.username){
            this.setState({ loggedIn: true});
        }
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value });
    }

    handleChange = location => {
        this.setState({ location_name : location.label, location : location});
    }
    
    exportExcel = async () => {

        let searchInputs = {
            name: this.state.name,
            startId: 0,
        };

        await this.setState({ loading: true });

        setTimeout(() => {
            axios.get("/api/licenceExportexcell", {params: { searchInputs: searchInputs}, responseType: 'blob'} )
                .then(res => {
                    this.setState({ loading: false });
                    this.openBlob(res.data,  'xlsx');
                })
                .catch((error) => {
                    this.setState({ loading: false });
            });
        }, 1000);
        
    }

    openBlob(data, outType){
        var file;
        if (outType == 'word'){
            file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
        }
        else
        {
            if (outType == 'pdf'){
                file = new Blob([data], {type: 'application/pdf'});
            }
            else
            {
                file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                file = new File([file], 'korisnici.xlsx', {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            }    
        }
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
       
    }

    getDataTable = () => {
        return (
            <DataTable data={this.props.licences_data} isAdmin={this.state.isAdmin} onSearchLevy={this.props.onSearchLevy} />
        );
    }

    generatePagingButtons = () => {
        if(parseInt(this.props.licences_count) > 0){
            let numOfPages = parseInt(this.props.licences_count) / 5;
            let i = this.state.startBtn;
            let pages = [];
            let counter = 0;

            while( i < numOfPages && counter < 10){
                pages.push(<li className={i === this.state.currentTablePage ? 'active' : 'waves-effect'} key={i}><a  className="" onClick={(e) => this.changeTablePage(e)}>{i + 1}</a></li>);
                i++;
                counter++;
            }
            return pages;
        }
        return null;
    }

    changeTablePage(event){
        let value = event.target.text;
        if(value === '...') 
            return;

        let {startBtn} = this.state;
        let pageNum = parseInt(value) - 1;

        startBtn = pageNum > 5 ? (pageNum - 5) : 0; 

        this.setState({ currentTablePage : pageNum, startBtn });
        this.props.getSearchedLicences({...this.props.searchedLicenceData, startId : pageNum * 5, isAdmin : this.state.isAdmin});
    }

    getNextTablePage = () => {
        let page = this.state.currentTablePage + 1;
        let startButton = this.state.startBtn;
        
        if(this.props.licences_count/10 < page) return;

        if( page > startButton + 5)
            this.setState({ currentTablePage : page, startBtn : startButton+1});
        else
            this.setState({ currentTablePage : page, startBtn : startButton});

        this.props.getSearchedLicences({...this.props.searchedLicenceData, startId : page*10, isAdmin : this.state.isAdmin});
    }

    getPreviousTablePage = () => {
        let startButton = this.state.startBtn;
        let currentPage = this.state.currentTablePage;
        if(currentPage === 0) return;

        if( currentPage >= 5 && startButton >= 1)
            this.setState({ currentTablePage : currentPage - 1, startBtn : startButton-1});
        else
            this.setState({ currentTablePage : currentPage - 1, startBtn : startButton});

        this.props.getSearchedLicences({...this.props.searchedLicenceData, startId : (currentPage - 1)*10, isAdmin : this.state.isAdmin});
    }

    handleSubmit = async() => {
        this.props.getSearchedLicences({name: this.state.name, startId: 0, isAdmin: this.state.isAdmin});
        await this.setState({ startBtn: 0, startId: 0,currentTablePage: 0});
    }
    
    render() {
        let pages = this.generatePagingButtons();
        return (
            <div>
                <div className="row" style={{ margin: 0, padding : 0}}>
                    <div className="col s12 m3">
                        <div className="input-field col s12">
                            <input   id="licname" name="licname" type="text" className="validate" value={this.state.name} onChange={(e) => { this.setState({ name: e.currentTarget.value})}} />
                            <label htmlFor="licname">{lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME')}</label>
                        </div>
                    </div>
                    
                    <div className="col s12 m6" style={{marginTop : 15}}>
                        <button className="btn waves-effect waves-light" type="button" name="searchBtn" style={{maxWidth : 250, height: 50}} onClick={this.handleSubmit}>
                            {lblSrvc.getLabel(this.props.selectedLanguage, 'SEARCH_TABLE')}
                            <i className="material-icons left">search</i>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="hide-on-small-only">
                        <div className="right">
                            <div className="input-col">
                                <img src={require("./exportExcel.png")} width="32" style={{ cursor: 'pointer'}} onClick={() => this.exportExcel()} />
                            </div>
                        </div>
                    </div>
                </div>
               
                {this.getDataTable()}

                {!pages || pages.length <= 0 ? (null) : (
                    <ul className="pagination">
                        <li className="disabled">
                            <a href="#!" onClick= {() => this.getPreviousTablePage() }>
                                <i className="material-icons">chevron_left</i>
                            </a>
                        </li>
                        {pages}
                        <li className="waves-effect"><a href="#!" onClick= {() => this.getNextTablePage()}><i className="material-icons">chevron_right</i></a></li>
                    </ul>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    licences_count: state.licences.count,
    licences_data: state.licences.data,
    selectedLanguage: state.language,
    searchedLicenceData: state.licences.searchedLicenceData,
    isLoading: state.licences.isLoading,
    reg_count: state.licences.reg_count,
    
});

export default connect(mapStateToProps, { getSearchedLicences })(LicenseSearchForm);