import  React, { Component } from 'react'
import Header  from '../Header/Header';
import  DataTable  from './DataTable/dataTable';
import  { connect } from 'react-redux';
import  lblSrvc from '../../labelService';
import  { getNextTenRegisters, getNumberOfRegisters, getSearchedRegisters, setRegistersCount } from '../../actions/RegisterActions';
import  PropTypes from 'prop-types';
import SearchForm from './SearchForm/SearchForm';
import LicenseSearchForm  from './License/LicenseSearchForm';
import  './table.css';
import Comments from './Comments/Comments';
import { userConstants } from "../../_constants/userConstants";
import Spiner from '../Spiner/Spiner'
import axios from "axios";

export class SearchPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentTablePage : 0,
            selected: [],
            openComments : false,
            selectedRegister : 0,
            startBtn : 0,
            isAdmin : false,
            countByGroupName: '-',
            countByTypeName: '-',
            countByRegulation: '-',
            scrollY : 0,
            currentTab: 0,
            nameFromLicence: undefined
        };
        this.changeTablePage = this.changeTablePage.bind(this);
    }

    async componentDidMount() {
        //window.scrollTo(0, 0);
        const M = window.M;
        let paral = document.querySelector('.tabs');
        var instance = M.Tabs.init(paral, {});
        await this.loadUser();
        this.getRegistersCount();
    }
      
    loadUser() {
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data ) return;
        if(data.isAdmin){
            this.setState({ isAdmin: true });
        }
    }

    getRegistersCount(){
        axios.post("/api/getRegistersCount",  { isAdmin : this.state.isAdmin })
            .then(res => {
                let countByGroupName = res.data.countByTypeName;
                let countByTypeName = res.data.countByGroupName;
                let countByRegulation = res.data.countByRegulation;

                this.props.setRegistersCount({countByGroupName, countByTypeName, countByRegulation});
            })
            .catch(function(error) {
        });
    }
    
    openCommentSection = (id) => {
        //window.scrollTo(0, 0);
        this.setState({ selectedRegister : id, openComments : true});

    }

    closeCommentSection = () => {
        this.setState({ openComments : false, selectedRegister : 0});
    }

    getNextTablePage = () => {
        let page = this.state.currentTablePage + 1;
        let startButton = this.state.startBtn;
        
        if(this.props.registers_count/10 < page) return;

        if( page > startButton + 5)
            this.setState({ currentTablePage : page, startBtn : startButton+1});
        else
            this.setState({ currentTablePage : page, startBtn : startButton});
        //fetch searched registers
        this.props.getSearchedRegisters({...this.props.searchedData, startId : page*10, isAdmin : this.state.isAdmin});
    }

    getPreviousTablePage = () => {
        let startButton = this.state.startBtn;
        let currentPage = this.state.currentTablePage;
        if(currentPage === 0) return;

        if( currentPage >= 5 && startButton >= 1)
            this.setState({ currentTablePage : currentPage - 1, startBtn : startButton-1});
        else
            this.setState({ currentTablePage : currentPage - 1, startBtn : startButton});

        //this.setState({ currentTablePage : currentPage - 1, startBtn: startButton - 1});
        //fetch searched registers
        this.props.getSearchedRegisters({...this.props.searchedData, startId : (currentPage - 1)*10, isAdmin : this.state.isAdmin});
    }

    getDataTable = () => {
        return (
            <DataTable data={this.props.registers_data} onCommentClick={this.openCommentSection} isAdmin={this.state.isAdmin}/>
        );
    }

    changeTablePage(event){
        let value = event.target.text;
        if(value === '...') 
            return;

        let {startBtn} = this.state;
        let pageNum = parseInt(value) - 1;

        startBtn = pageNum > 5 ? (pageNum - 5) : 0; 

        this.setState({ currentTablePage : pageNum, startBtn });
        this.props.getSearchedRegisters({...this.props.searchedData, startId : pageNum * 5, isAdmin : this.state.isAdmin});
    }

    generatePagingButtons = () => {
        
        if(parseInt(this.props.registers_count) > 0){
            let numOfPages = parseInt(this.props.registers_count) / 5;
            let i = this.state.startBtn;
            let pages = [];
            let counter = 0;

            while( i < numOfPages && counter < 10){
                pages.push(<li className={i === this.state.currentTablePage ? 'active' : 'waves-effect'} key={i}><a  className="" onClick={(e) => this.changeTablePage(e)}>{i + 1}</a></li>);
                i++;
                counter++;
            }
            return pages;
        }
        return null;
    }

    refreshTable = () => {
        this.setState({
            currentTablePage : 0,
            startBtn : 0,
        });
    }

    generateSearchSection(){
        let pages = this.generatePagingButtons();
        return (
            <div>
                <SearchForm search_result={this.props.match.params} onRefreshTable={ () => this.refreshTable()} nameFromLicence={this.state.nameFromLicence}/>
                {this.getDataTable()}
                {!pages || pages.length <= 0 ? (null) : (
                    <ul className="pagination">
                        <li className="disabled">
                            <a href="#!" onClick= {() => this.getPreviousTablePage() }>
                                <i className="material-icons">chevron_left</i>
                            </a>
                        </li>
                        {pages}
                        <li className="waves-effect"><a href="#!" onClick= {() => this.getNextTablePage()}><i className="material-icons">chevron_right</i></a></li>
                    </ul>
                )}
            </div>
        );
    }

    generatelicencSection(){
        return (
            <LicenseSearchForm onSearchLevy={this.searchLevy}/>
        );
    }

    searchLevy = async (name) => {
        await this.props.getSearchedRegisters({
            name : name, startId : 0, isAdmin : this.state.isAdmin
        });
        await this.setState({
            currentTablePage : 0,
            startBtn : 0,
            nameFromLicence : name,
        }); 
        this.tab1.click();
        window.scrollTo(0, 900);
    }

    generateCommentSection(){
        return (
            <div>
                <Comments register_id={this.state.selectedRegister} closeComments={this.closeCommentSection} user_info={this.state.user_info}/>
            </div>
        );
    }

    generateMainSection(){
        if(this.state.openComments) return this.generateCommentSection();
        else return this.generateSearchSection();
    }

    getSectionTitle(){
        if(this.state.openComments) return 'SEARCH_PAGE_COMMENTS_TITLE';
        else return 'SEARCH_PAGE_TABLE_TITLE';
    }

    generateBannerText = () => {

        if(this.state.currentTab === 0)
            return (
                <div className="scc-banner-title">
                    <h5>
                        {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_BANNER_SEARCH_TITLE_FIRST')}
                    </h5>
                    <h5>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_BANNER_SEARCH_TITLE_SECOND')}</h5>
                </div>
                
            );
        else 
            return (
                <div className="scc-banner-title">
                    <h5>
                        {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_BANNER_LICENCE_TITLE_FIRST')}
                    </h5>
                    <h5>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_BANNER_LICENCE_TITLE_SECOND')}</h5>
                    <h5>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_BANNER_LICENCE_TITLE_THIRD')}</h5>
                </div>
                
            );
    }

    changeTab(e,tab){
        e.preventDefault();
        this.setState({ currentTab: tab});
    }

    /* 
    <li class="tab col s3 left"><a className={currentTab === 1 ? 'scc-tab-active' : 'scc-tab'}  href="#test-swipe-2" onClick={(e) => { this.changeTab(e,1)}}>{lblSrvc.getLabel(this.props.selectedLanguage, 'TAB_LICENCES')}</a></li>
    
    <div id="test-swipe-2" class="col s12 red">
                                        <div className="table-wrapper" style={{paddingTop: 10}}>
                                            <div className="table-title">
                                                <div className="row" style={{ padding: 17, justifyContent : 'space-between'}}>
                                                        <div  className="col s12 m6 scc-header-title" >
                                                            <h2>{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCES_PAGE_TABLE_TITLE')}</h2>
                                                        </div>
                                                </div>
                                            </div>
                                            {this.props.isLoading &&
                                                <Spiner></Spiner>
                                            }
                                            {licencSection}
                                        </div>
                                    </div>
    */
    render() {
        let registerSection = this.generateSearchSection();
        let licencSection = this.generatelicencSection();
        let bannerText = this.generateBannerText();
        let {currentTab } = this.state;
       
        return (
            <div className="scc-min-height">
                <Header openComments={this.state.openComments} onCloseComments={ () => { this.setState({openComments: false})}}/>
                <div className="scc-page-container">
                    <div  style={{ backgroundImage: `url(${require('./guide.png')})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
                        <div className="scc-banner-container centered-container"  style={{ backgroundColor : 'rgba(47, 43, 43, 0.5)'}} >
                            {bannerText}
                        </div>
                    </div>
                    <div className={"scc-page-content"} style={{marginBottom: 120}}>
                        <div className="scc-search-section ">
                            <div className="container" style={{marginTop: 65}}>
                                <div style={{ flex: 1, justifyContent: 'center', alignItems: "center"}} >
                                    <ul id="tabs-swipe-demo"  class="tabs center tab-demo z-depth-1 scc-tab-header" style={{paddingBottom : 20}} >
                                        <li class="tab col s3 left"><a className={currentTab === 0 ? 'scc-tab-active' : 'scc-tab'} ref={tab1 => this.tab1 = tab1}   href="#test-swipe-1" onClick={(e) => { this.changeTab(e,0)}}>{lblSrvc.getLabel(this.props.selectedLanguage, 'TAB_REGISTERS')}</a></li>
                                    </ul>
                                    <div id="test-swipe-1" class="col s12 blue">
                                        <div className="table-wrapper" style={{paddingTop: 11, marginTop: 26}}>
                                            <div className="table-title">
                                                <div className="row" style={{ padding: 17, justifyContent : 'space-between'}}>
                                                        <div  className="col s12 m6 scc-header-title" >
                                                            <h2>{lblSrvc.getLabel(this.props.selectedLanguage, 'SEARCH_PAGE_TABLE_TITLE')}</h2>
                                                            <br/>
                                                            {this.props.selectedLanguage === 'ENG' &&
                                                                <span className="scc-search-footnote-eng">{lblSrvc.getLabel(this.props.selectedLanguage, 'SEARCH_PAGE_TABLE_SUB_TITLE_ENG')}</span>
                                                            }
                                                        </div>
                                                    <div  className="col s12 m6" style={{paddingTop: 14}}>
                                                        <div className="scc-flex-center " style={{paddingBottom: 15}}>
                                                            <span className="scc-count-label" >{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_GROUP_COUNT_LABEL') + ': '}  </span>  <span className="scc-count-value" >{this.props.reg_count.countByTypeName}</span>
                                                        </div>
                                                        <div className="scc-flex-center " style={{paddingBottom: 15}}>
                                                            <span className="scc-count-label"> {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_TYPE_COUNT_LABEL')+ ': '}</span> <span className="scc-count-value" >{this.props.reg_count.countByGroupName}</span> 
                                                        </div>
                                                        <div className="scc-flex-center " >
                                                            <span className="scc-count-label"> {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_REG_COUNT_LABEL')+ ': '}</span> <span className="scc-count-value" >{this.props.reg_count.countByRegulation}</span> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.props.isLoading &&
                                                <Spiner></Spiner>
                                            }
                                            {registerSection}
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}

SearchPage.propTypes = {
    getNextTenRegisters: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    registers_count: state.registers.count,
    registers_data: state.registers.data,
    selectedLanguage: state.language,
    searchedData: state.registers.searchedData,
    isLoading: state.registers.isLoading,
    reg_count: state.registers.reg_count,
    
});

export default connect(mapStateToProps, { getNextTenRegisters, getNumberOfRegisters, getSearchedRegisters, setRegistersCount })(SearchPage);