import { SEARCH } from './types';
import axios from 'axios';
import {userConstants} from '../_constants/userConstants';

export const getNextTenRegisters = (value) => dispatch => {
  axios.get('/api/getNextTenRegisters/'+value,{
    value
  })
    .then(res => {
        dispatch({
            type: SEARCH,
            registers: res.data
        })
    }
    ,error => {
  });
};

export const getNumberOfRegisters = () => dispatch => {
  axios.get('/api/getNumberOfRegisters',{})
    .then(res => {
        dispatch({
            type: 'REGISTERS_COUNT',
            registersCount: res.data[0].count
        })
    }
    ,error => {
  });
};

export const getSearchedRegisters = (value) => dispatch => {
  dispatch(loadingStatus(true));
  //console.log(value);
  axios.post('/api/getSearchedRegisters',value)
    .then(res => {
        dispatch({
            type: SEARCH,
            registers: res.data.registers,
            registersCount: res.data.totalCount,
            searchedData: value,
        });
        dispatch(loadingStatus(false));
    }
    ,error => {
  });
};

export const loadingStatus = (bool) => {
  return {
      type: 'LOADING',
      isLoading: bool
  };
}
export const selectRegister = (id) => {
  return {
      type: 'SELECT_REGISTER',
      selectedRegister: id
  };
}

export const setRegistersCount = (reg_count) => { 
	return { type: userConstants.REG_COUNT, reg_count };
}
