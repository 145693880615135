import React, { Component } from 'react'
import { connect } from 'react-redux';
import lblSrvc from '../../../labelService';
import './graphSection.css';

export class GraphSection extends Component {
    constructor(props){
        super(props);
        this.state = {
            readmore : true,
        }
    }
    
    getClass = () =>
    {
        if(this.state.readmore){
            return "sidebar-box scc-main-paragraph graphText";
        }
        else {
            return "sidebar-box graphText";
        }
    }

    showMore = () =>{
        this.setState({readmore : false})
    }

    showLess = () =>{
        this.setState({readmore : true})
    }

    render() {

        return (
            <div className="scc-graph-section">
                <div className="center-align">
                    <div className="scc-graph-section-title whoAreWe">{lblSrvc.getLabel(this.props.selectedLanguage, 'MAIN_GRAPH_SECTION_TITLE')}</div>
                </div>
                <div className="container" >
                    <div className="row centered-container">
                        <div className="col s12 m6">
                            <img  src="https://image.freepik.com/free-vector/online-tutorials-illustration-concept_23-2148525396.jpg"/>
                        </div>
                        <div  className="col s12 m6">
                            <div>
                                <div className="scc-main-paragraph graphText">
                                    {lblSrvc.getLabel(this.props.selectedLanguage, 'GOAL')}
                                </div>
                            </div>
                            {this.state.readmore &&
                            <div>
                                <div className={this.getClass()}>
                                    {lblSrvc.getLabel(this.props.selectedLanguage, 'GOAL_FULL')}
                                      <p className="read-more"></p>
                                </div>
                                <div className="row centered-container">
                                    <a href="javascript:void(0)" onClick={this.showMore}>{lblSrvc.getLabel(this.props.selectedLanguage, 'READ_MORE')}</a>
                                    
                                </div>
                            </div>
                            }
                            {!this.state.readmore &&
                            <div>
                                <div className="scc-main-paragraph graphText">
                                    {lblSrvc.getLabel(this.props.selectedLanguage, 'GOAL_FULL')}
                                </div>
                                <div className="row centered-container">
                                    <a href="javascript:void(0)" onClick={this.showLess}>{lblSrvc.getLabel(this.props.selectedLanguage, 'READ_LESS')}</a>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="row centered-container">
                        <div  className="col s12 m6">
                            <div>
                                <p className="scc-main-paragraph graphText">
                                    {lblSrvc.getLabel(this.props.selectedLanguage, 'ABOUT_SCC')}
                                </p>
                            </div>
                        </div>
                        <div className="col s12 m6 center">
                            {this.props.selectedLanguage == 'ENG' &&
                            <img src={require("./scc_logo_eng.png")} className="scc-header-image" width="500"/>
                            }
                            {this.props.selectedLanguage != 'ENG' &&
                            <img src={require("./scc_logo_mne.png")} className="scc-header-image" width="500"/>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {  })(GraphSection);
