import React, { Component } from 'react'

export class ParallaxImage extends Component {

    componentDidMount() {
        const M = window.M;
        let paral = document.querySelector('.parallax');
        M.Parallax.init(paral);
    }
    
    render() {
        return (
            <div className="parallax-container">
                <div className="parallax" style={{}}><img src={require('../calc3.jpg')}  alt="" /></div>
            </div>
        )
    }
}

export default ParallaxImage
