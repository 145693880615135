import React, { Component } from 'react';
import TableRow from './TableRow';
import { connect } from 'react-redux';
import lblSrvc from '../../../../labelService';

export class DataTable extends Component {

    constructor(props){
        super(props);
    }

    generateTableHeaderColumns(){
        return (
            <tr className="scc-table-header-text">
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_NAME')}</th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_NAME_ENG')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_LINK')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_DIR')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND1')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND2')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND3')} </th>
                <th className="scc-table-cell">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND4')} </th>
            </tr>
        );
    }

    generateRows = () => {
        if(this.props.data == undefined) return null;
        
        return this.props.data.map((register, index) => (
            <TableRow data={register} key={index} id={index} isAdmin={this.props.isAdmin} onSearchLevy={this.props.onSearchLevy}/>
        ));
    }

    render() {
        return (
            <div style={{overflowX : 'scroll'}}>
                <table className="table table-striped table-hover">
                    <thead>
                       {this.generateTableHeaderColumns()}
                    </thead>
                    <tbody>
                       {this.generateRows()}
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {   })(DataTable);