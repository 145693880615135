import React, { Component } from "react";
import AsyncSelect from "react-select/async";

const selectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '45px',
    height: '45px',
    boxShadow: state.isFocused ? null : null,
    border: '1px solid #26a69a !important'
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '45px',
    padding: '0 6px',
    'flex-direction': 'column',
  }),
  multiValue: (provided, state) => ({
      ...provided,
      marginTop: '10px',
      backgroundColor: '#2ad2c2',
      color: 'white',
  }),
  placeholder: (provided, state) => ({
      display: 'none'
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  menu: (provided, state) => ({
      ...provided,
      'background-color' : 'white',
      'z-index': '100000',
    }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '50px',
  }),
  option: (provided, { isSelected }) => {
      if(isSelected){
          return { 
              ...provided,
              backgroundColor: '#26a69a',
          };
      }
      return {
          ...provided,
      }
    },
};

class AsyncMulti extends Component {

  constructor(props){
    super(props);

    this.state = {
      inputValue: ''
    }
  }

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  filterOptions = (inputValue) => {
    return this.props.options.filter((i) =>
      i.label.toLowerCase().includes(this.state.inputValue.toLowerCase())
    );
  };
  
  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterOptions(inputValue));
      }, 2222);
    });


  render() {
    return (
      <AsyncSelect
        {...this.props}
        styles={selectCustomStyles}
        isDisabled={this.props.isDisabled}
        cacheOptions
        defaultOptions
        loadOptions={this.promiseOptions}
        onInputChange={this.handleInputChange}
      />
    );
  }
}

export default AsyncMulti;