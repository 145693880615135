import React, { Component } from 'react'
import  { connect } from 'react-redux';
import  lblSrvc from '../../../labelService';
import axios from "axios";
import Spiner from '../../Spiner/Spiner'
import { userConstants } from "../../../_constants/userConstants";
import moment from 'moment';
import 'moment/locale/sr';
import { Login } from '../../Login/Login';


export class CommentsPage extends Component {

    constructor(props){
        super(props);

        this.state = {
            comments : [],
            comment_text : '',
            loading : false,
            loggedIn : false,
            isAdmin : false,
        };
        this.getComments = this.getComments.bind(this);
        this.onSendComment = this.onSendComment.bind(this);

    }

    componentDidMount(){
        //window.scrollTo(0, 0);
        this.loadUser();
        this.getComments();
    }

    getJwt() {
        return "Bearer " + localStorage.getItem(userConstants.MEIS_EDU_TOKEN);
    }

    loadUser() {
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data || !data.isAdmin) {
            this.setState({ isAdmin: false});
        }else {
            this.setState({ isAdmin: true});
        }

        const jwt = this.getJwt();

        // checks if there is a token

        if (!jwt || jwt == 'Bearer null') {
          this.setState({ loggedIn: false });
          return;
        }else {
            axios.get("/api/getUserData", { headers: { Authorization: jwt } })
                .then(res => {
                    console.log(res.data);
                    if(res.data.username == '')this.setState({ loggedIn: false });
                    else
                     this.setState({ user_info: res.data, loggedIn: true });
                })
                .catch(function(error) {
                        this.setState({ loggedIn: false });
            });
        }
        
    }

    async getComments() {
        await this.setState({ loading: true });
        axios.get("/api/getComments/"+this.props.register_id,  {  })
            .then(res => {
                this.setState({ comments : res.data, loading : false});
            })
            .catch(function(error) {
              this.props.closeComments();
            }
        );
    }

    async onSendComment() { 
        if(this.state.comment_text === '' || this.state.comment_text == undefined || this.state.comment_text == null ) return;

        await this.setState({ loading: true });
        let value = { text : this.state.comment_text, user_id : this.state.user_info.id, date : new Date().getTime(), register_id : this.props.register_id };
        axios.post("/api/addComment", value)
            .then(res => {
                this.generateNewComment(value);
                this.setState({ loading: false });
            })
            .catch(function(error) {
            }
        );
    }

    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }

    getValidDate = (date) => {
        moment.locale('sr');

        return moment(date).format('lll');
    }
   
    generateNewComment = (data) => {
        let new_comment = {
            Datum: data.date,
            Nameti_id: data.register_id,
            Tekst: data.text,
            first_name: this.state.user_info.first_name,
            last_name: this.state.user_info.last_name,
        };

        let comments = [...this.state.comments, new_comment];
        this.setState({ comments : comments, comment_text : ''});
    }

  
    generateComments(){
        let comments = [];
        let comments_data = this.state.comments;
        for (let i = 0; i < comments_data.length; i++) {
            let comment = comments_data[i];
            comments.push(this.generateComment(comment.Tekst, comment.first_name +' '+comment.last_name, comment.email, parseInt(comment.Datum),i));
        }
        return comments;
    }

    generateComment(text, name, email, date, id){
        let dateString = new Date(date);
        
        return (
            <div key={id} className="card" style={{ padding : 20, boxShadow : '0 0px 5px 0 rgba(0,0,0,0.14), 0 0px 0px 0 rgba(0,0,0,0.12), 0 0px 1px 0px rgba(0,0,0,0.2)'}}>
                <div className="row" style={{minHeight : 25, borderBottom: '1px solid #8080801c', paddingBottom: 10}}>
                    <div className="col s6">
                        <span style={{marginTop : 0, fontSize : 18, fontWeight : 500}}>{name}</span>
                        {this.state.isAdmin ? <span style={{marginLeft: 10, textDecoration: 'underline'}}><a href={"mailto:"+email}>{email}</a></span> : null}
                    </div>
                    <div className="scc-comment-date">{this.getValidDate(dateString)}</div>
                </div>
                <div className="row" style={{minHeight : 50}}>
                    <div className="col s11" style={{}}>
                        {text}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let comments = this.generateComments();

        return (
            <div>
                {this.state.loading &&
                    <Spiner></Spiner>
                }
                <div className="input-field col s2" style={{ marginBottom : 30}}>
                    <button className="btn waves-effect waves-light" type="button" name="back" style={{marginTop: 20, paddingLeft : 15}} onClick={this.props.closeComments}>
                        {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_COMMENT_BACK')}
                        <i className="material-icons left" style={{fontSize : 20}}>keyboard_backspace</i>
                    </button>
                </div>
                <div className="card" style={{ padding : 20}}>
                        <div className="row">
                            <div className="input-field col s10">
                                <i className="material-icons prefix" style={{ marginTop : 35}}>mode_edit</i>
                                <textarea id="comment_text" disabled={!this.state.loggedIn} name="comment_text" className="materialize-textarea" value={this.state.comment_text} onChange={this.onChange}></textarea>
                                <label htmlFor="comment_text">
                                    {this.state.loggedIn 
                                        ? lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_COMMENT_TEXT')
                                        : lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_COMMENT_TEXT_DISABLED')}
                                    </label>
                            </div>
                            <div className="input-field col s2">
                                <button className="btn waves-effect waves-light" disabled={!this.state.loggedIn} type="button" name="action" style={{marginTop: 20}} onClick={this.onSendComment}>
                                    {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_COMMENT_SEND')}
                                    <i className="material-icons right">send</i>
                                </button>
                            </div>
                        </div>
                    </div>
                {comments}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {  })(CommentsPage);