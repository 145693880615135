import React, { Component } from 'react'
import Header  from '../Header/Header';
import Footer  from '../Footer/Footer';
import ImageSection from './ImageSection/imageSection';
import KoefKonChart from './KoefKonChart/KoefKonChart.js'
import GraphSection from './GraphSection/graphSection';
import CardsSection from './CardsSection/cards';
import ContactSection from './ContactSection/ContactSection';
import { userConstants } from "../../_constants/userConstants";
import  lblSrvc from '../../labelService';
import { connect } from 'react-redux';
import axios from "axios";

export class Main extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    //<KoefKonChart className="frame"/>
    render() {
        return (
            <div>
                <Header />
                <div className="scc-page-container">
                    <ImageSection />
                    <GraphSection/>
                    <CardsSection />
                    
                    <ContactSection />                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {  })(Main);