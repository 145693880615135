export const EngLabels = {
    HOME                                : 'Home',
    CONTACT                             : 'Contact',
    REPORTS                             : 'Reports',
    GOAL                                : 'Registry of levies contains a list of levies at national and local level which must be paid by private and legal entities. The basic goal for creating such registry is to have centralized data on the levies charged in Montenegro, at national and local level, in order to simplify business activities and increase transparency.',
    GOAL_FULL                           : 'The levies are described, among others, by the categories defining: levy type, amount, payment manner, area or department which applies it, regulations which are the basis to adopt and define the levy amount. The issue of a registry uniting all the levies at national and local level has been a very important topic for a while initiated as well by the Government and business associations, as a measure to improve business environment in Montenegro. The Registry enables public sector to have insight into levy burdens per industry, to make an analysis of the necessity for the existing levies and to make decision following these inputs regarding the cases where the levies can be reduced, postponed or cancelled. Such database should help decision-makers when creating new legislation and when defining new levies. Conversely, such Registry helps the industries to have an overview of a list of levies in a transparent manner including their amount for the areas related to their operations as well as the possibility to calculate and plan in advance the costs related to their obligations towards public sector. The Registry is a dynamic database  which is amended in line with the amended regulations prescribing the levies at national and local level. The date of the last levy modification can be traced via „date of last data update“. The levies covered by this Registry include: excise taxes, duties, donations, contributions, securities, interests, loans, penalties, charges, taxes and surtaxes, borrowings, revenues, sales, fees, costs and custom duties. ',
    ABOUT_SCC                           : 'The Secretariat of the Competitiveness Council, supported by the EBRD, in collaboration with the UK Government, coordinated and financed development of the register, in cooperation with a working group composed of members from public institutions, business associations and Union of municipalities. The working group monitored the project implementation process and contributed to the quality of the register at each stage by giving suggestions and comments.',
    SEARCH                              : 'Search',
    SEARCH_TABLE                        : 'Start searching',
    MAIN_TITLE                          : 'Registry of levies',
    MAIN_SUB_TITLE                      : 'All information in one place',
    MAIN_BUTTON_LABEL                   : 'Search',
    MAIN_GRAPH_SECTION_TITLE            : 'About the Registry',
    MAIN_GRAPH_SECTION_SUB_TITLE        : 'NEW DESIGN',
    MAIN_GRAPH_SECTION_TEXT             : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In molestie, diam id tincidunt vestibulum, arcu turpis tempor eros, eget gravida enim magna a nulla. Morbi ornare massa vel tellus aliquet, ac tempus eros ornare. Aenean dictum augue sed luctus bibendum. Fusce a sagittis odio.',
    MAIN_CARDS_TITLE                    : '',
    SEARCH_PAGE_TITLE                   : 'Registries table',
    SEARCH_PAGE_SUB_TITLE               : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In molestie, diam id tincidunt vestibulum.',
    SEARCH_PAGE_TABLE_TITLE             : 'Search the Registry of levies',
    SEARCH_PAGE_TABLE_SUB_TITLE_ENG     : '* The content of the data base is not available in English',
    SEARCH_PAGE_TABLE_SUB_TITLE         : 'Table',
    REGISTER_TABLE_COLUMN_STATE         : 'Status',
    REGISTER_TABLE_COLUMN_REG_NUMBER    : 'Levy registration number',
    REGISTER_TABLE_COLUMN_NAME          : 'Name',
    REGISTER_TABLE_COLUMN_NAME_ENG      : 'Name in English',
    REGISTER_TABLE_COLUMN_AMOUNT        : 'Amount',
    REGISTER_TABLE_COLUMN_INSTITUTION   : 'Institution',
    REGISTER_TABLE_COLUMN_LOCATION      : 'Location',
    REGISTER_TABLE_COLUMN_SECTOR        : 'Sector',
    REGISTER_TABLE_COLUMN_SCRIPT        : 'Regulation',
    REGISTER_TABLE_COLUMN_STATE         : 'Status',
    REGISTER_TABLE_COLUMN_REG_SCRIPT    : 'Levy regulation',
    REGISTER_TABLE_COLUMN_SCRIPT_AMOUNT : 'Levy amount regulation',
    REGISTER_TABLE_COLUMN_TAX           : 'Tax payment',
    REGISTER_TABLE_COLUMN_COST_FROM     : 'Amount from',
    REGISTER_TABLE_COLUMN_COST_TO       : 'Amount to',
    REGISTER_TABLE_COLUMN_COST_PRE_TO   : 'Amount in percentage up to',
    REGISTER_TABLE_COLUMN_COST_PRE_FROM : 'Amount in percentage from',
    REGISTER_TABLE_COLUMN_TYPE          : 'Levy type',
    REGISTER_TABLE_COLUMN_FREQUENCY     : 'Payment frequency',
    REGISTER_TABLE_COLUMN_PAYMENT_TYPE  : 'Payment manner',
    REGISTER_TABLE_COLUMN_BASE          : 'Base',
    REGISTER_TABLE_COLUMN_ORGAN         : 'Body',
    REGISTER_TABLE_COLUMN_OBLIGATOR     : 'Tax payer',
    REGISTER_TABLE_COLUMN_OBLIGATOR_TYPE: 'Tax payer type',
    REGISTER_TABLE_COLUMN_DATE          : 'Collection date',
    REGISTER_TABLE_COLUMN_BANK_ACC      : 'Bank account',
    REGISTER_TABLE_COLUMN_CODE_D        : 'Code D',
    REGISTER_TABLE_COLUMN_CODE_N        : 'Code N',
    REGISTER_TABLE_COLUMN_EXCEPTIONS    : 'Exceptions',
    REGISTER_TABLE_COLUMN_DATE_UPDATE   : 'Last data update',
    REGISTER_TABLE_COLUMN_PROCEDURE_NUM : 'Procedure number',
    REGISTER_TABLE_COLUMN_PROCEDURE     : 'Procedure',
    REGISTER_TABLE_COLUMN_GROUP_NAME    : 'Group name',
    REGISTER_TABLE_COLUMN_SORTED_NAME   : 'Disaggregated name',
    REGISTER_TABLE_ROWS_DISPLAYED       : 'Displayed',
    REGISTER_TABLE_ROWS_DISPLAYED_OF    : 'of',
    REGISTER_TABLE_ROWS                 : 'rows',
    REGISTER_TABLE_SEARCH               : 'Search',            
    FOOTER_TITLE                        : 'Footer title',
    FOOTER_SUB_TITLE                    : 'Footer subtitle',
    FOOTER_LINK                         : 'Footer link',
    FOOTER_COPYRIGHT                    : '© 2020 Copyright',
    FOOTER_BOTTOM_RIGHT                 : 'More links',
    FOOTER_LINK_TITLE                   : 'Links',
    SEARCH_PAGE_COMMENTS_TITLE          : 'Add comment',
    LOGIN                               : 'Log in',
    LOGOUT                              : 'Log out',
    SCC_COMMENT_TEXT                    : 'Enter your comment',
    SCC_COMMENT_SEND                    : 'SEND',
    SCC_COMMENT_BACK                    : 'Back',
    SCC_COMMENT_TEXT_DISABLED           : 'Sign in to leave a comment',
    SCC_FOOTER_TEXT                     : 'Copyright © Ministry of Economic Development of Montenegro 2020',
    ADD_REGISTER                        : 'Add new levy',
    TOAST_DEFINE_FIELD                  : 'Mandatory field',
    NAME                                : 'Name',
    CANCEL                              : 'Cancel',
    ADD                                 : 'Add',
    SAVE                                : 'Save',
    ADMIN_REGISTER_TITLE                : 'Update data about levy',
    ADMIN_ADD_REGISTER_TITLE            : 'Add new levy',
    USER_REGISTER_TITLE                 : 'Review information about levy',
    SCC_BANNER_COMMENTS_TITLE           : 'Note for comments',
    SCC_BANNER_SEARCH_TITLE_FIRST       : 'In order to improve the Registry, the comments are enabled. If a levy is already included in the Registry, comments are entered for each levy respectively, with mandatory registration and logging in of the users. In cases when a levy charged in practice is not in the database, the comments may be sent by e-mail to scc@scc.org.me.',
    SCC_BANNER_SEARCH_TITLE_SECOND      : 'Registry search is performed by all categories defined in the database. It is possible to select more options for one category.',
    SCC_BANNER_PUBLIC_SECTOR            : 'Public sector: the Registry enables the public sector to improve transparency and it facilitates the levies overview at national and local level. The Registry is a good input for decision-making process related to the levy amount reduction or cancellation, as well as for planning new regulation adoption and defining new levies.',
    SCC_BANNER_CITIZENS                 : 'Citizens: the Registry provides for a simple overview of the obligations which the citizens have towards public administration and local self-government.',
    SCC_BANNER_ECONOMY                  : 'Economy: the Registry facilitates the search of the levies the businesses have to pay to ensure smooth business activities. Also, the business have a possibility to calculate and plan in advance the costs related to their obligations towards public sector.',
    PREVIUOS                            : 'Previous',
    NEXT                                : 'Next',
    REGISTER_TABLE_COLUMN_AREA1         : 'Regulation classification level 1',
    REGISTER_TABLE_COLUMN_AREA2         : 'Regulation classification level 2',
    READ_MORE                           : 'Read more',
    READ_LESS                           : 'Read less',
    PUBLIC_SECTOR                       : 'Registry and public sector', 
    PUBLIC_SECTOR_TEXT                  : 'The Registry provides for the public sector to improve transparency and it facilities the levies overview at national and local level. The Registry is a good input for decision-making process regarding levies amount reduction or their cancellation as well as for planning new regulation and defining new levies.', 
    CITIZENS                            : 'Registry and citizens', 
    CITIZENS_TEXT                       : 'the Registry provides for a simple overview of the obligations which the citizens have towards public administration and local self-government.', 
    ECONOMY                             : 'Registry and economy', 
    ECONOMY_TEXT                        : 'he Registry facilitates the search of the levies the businesses have to pay to ensure smooth business activities. Also, the businesses have a possibility to calculate and plan in advance the costs related to their obligations towards public sector.',
    SCC_MIN_FINANCY_MNE                 : 'Ministry of Economic Development of Montenegro',
    SCC_REGISTER                        : 'Registry of levies',
    SCC_LOGIN                           : 'Log in',
    SCC_USERNAME                        : 'Username',
    SCC_PASSWORD                        : 'Password',
    SCC_NO_ACCOUNT                      : 'No account?',
    SCC_REGISTRATION                    : 'Registration',
    SCC_FIRST_NAME                      : 'First name',
    SCC_LAST_NAME                       : 'Last name',
    SCC_EMAIL                           : 'E-mail',
    SCC_PASSWORD_CONFIRM                : 'Password (confirm)',
    SCC_SIGN_UP                         : 'Sign up',
    SCC_HAVE_ACCOUNT                    : 'Already have an account?',
    MAIN_CONTACT_SECTION_TITLE          : 'Contact us',
    MAIN_CONTACT_SECTION_ADRESS         : 'Address',
    MAIN_CONTACT_SECTION_CALL_US        : 'Call us',
    MORE_INFO                           : 'More information',
    REPORTS_TITLE                       : 'Download reports',
    REPORT_ERROR                        : 'Report error',
    REPORT_TYPES                        : 'Report types',
    REPORT_BUTTON                       : 'Download report',
    REPORT_BUTTON_SECOND                : 'Modify levy amount',
    REPORT_BUTTON_CHANGES               : 'Log of changes',
    REPORT_BUTTON_COEFCOM               : 'Coefficients of competitiveness',
    EDIT                                : 'Edit',
    READ_MORE                           : 'Read more',
    CONTACT_EMAIL                       : 'Contact and report an error via e-mail : ',
    EMAIL_ADRESS                        : 'scc@scc.org.me',
    CONTACT_NUMBER                      : 'Contact number of the SCC: ',
    PHONE_NUMBER                        : '+ 382 20651678',
    CONTACT_LOCATION                    : 'Address : ',
    LOCATION_ADRESS                     : 'Podgorica bb',
    SCC_GROUP_COUNT_LABEL               : 'Levy number in the Registry',
    SCC_TYPE_COUNT_LABEL                : 'Number of disaggregated levies in the Registry', 
    SCC_REG_COUNT_LABEL                 : 'Regulations that define the basis or amount of the levy', 
    USER_MANUAL                         : 'User manual', 
    SCC_FORGOTTEN_PASSWORD              : 'Forgotten password?', 
    SCC_SEND_VIA_MAIL                   : 'Send via e-mail',
    SEND                                : 'Send',
    REPORT_BUTTON_COMMENTS              : 'Download comments',
    REPORT_TYPES_LOCATION               : 'Location',
    REPORT_TYPES_AREALVL1               : 'Regulation classification level 1',
    REPORT_TYPES_TYPE                   : 'Levy type',
    REPORT_TYPES_INSTITUTION            : 'Institution',
    REPORT_TYPES_PAYMENT_TYPE           : 'Payment manner',
    REPORT_TYPES_PAYMENT_TIME           : 'Payment frequency',
    SAVE_DATA_SUCCESS                   : 'Success',
    SAVE_DATA_ERROR                     : 'An error occured while saving datas',
    SELECT_AREALVL1                     : "At least one item must be selected in the 'Regulation classification level 1' list",
    LICENCES_PAGE_TABLE_TITLE           : 'Search licences',
    SCC_BANNER_LICENCE_TITLE_FIRST      : 'The search of levies by license is performed by typing part of the license name. ',
    SCC_BANNER_LICENCE_TITLE_SECOND     : 'A list of levies that need to be paid in order to obtain a license will appear as a search result.',
    SCC_BANNER_LICENCE_TITLE_THIRD      : 'There is a "direct" levy, which is related only to a particular license and an "indirect" levy that can be linked to multiple licenses and is usually associated with providing of necessary documentation that is required to get a license.',
    LICENCE_COL_NAME                    : 'License name',
    LICENCE_COL_NAME_ENG                : 'License name on english',
    LICENCE_COL_LEVY_DIR                : 'Direct levy No. 1',
    LICENCE_COL_LEVY_LINK               : 'Link e-licence',
    LICENCE_COL_LEVY_CODE               : 'Code of direct levy No. 1',
    LICENCE_COL_LEVY_IND1               : 'Direct levy No. 2',
    LICENCE_COL_LEVY_IND1_CODE          : 'Direct levy code No. 2',
    LICENCE_COL_LEVY_IND2               : 'Direct levy No. 3',
    LICENCE_COL_LEVY_IND2_CODE          : 'Direct levy code No. 3',
    LICENCE_COL_LEVY_IND3               : 'Indirect levy No. 1',
    LICENCE_COL_LEVY_IND3_CODE          : 'Indirect levy code No. 1',
    LICENCE_COL_LEVY_IND4               : 'Indirect levy No. 2',
    LICENCE_COL_LEVY_IND4_CODE          : 'Indirect levy code No. 2',
    LICENCE_COL_LEVY_IND5               : 'Indirect levy No. 3',
    LICENCE_COL_LEVY_IND5_CODE          : 'Indirect levy code No. 3',
    LICENCE_COL_COMMENT                 : 'Comment',
    TAB_REGISTERS                       : 'Levies',
    TAB_LICENCES                        : 'Licences',
    DETAIL_SEARCH                       : 'Detail search',
    SIMPLE_SEARCH                       : 'General search',
    REGISTER_GEN_SEARCH_TERM            : 'Search term',

};
