import React, { Component } from 'react'
import { connect } from 'react-redux';
import lblSrvc from '../../../labelService';

export class CardsSection extends Component {
    render() {
        return (
            <div className="scc-cards-section">
                <div className="center-align">
                    <h3 className="scc-cards-section-title ">{lblSrvc.getLabel(this.props.selectedLanguage, 'MAIN_CARDS_TITLE')}</h3>
                </div>
                <div className="container ">
                    <div className="row centered-container">
                        <div  className="col s12 m4">
                            <li className="scc-cards_item">
                                <div className="scc-card">
                                <div className="scc-card_image"><img src={require('./dashboard.jpg')} /></div>
                                      <div className="scc-card_content">
                                          <div className="cardHeadline">{lblSrvc.getLabel(this.props.selectedLanguage, 'CITIZENS')}</div>
                                          <p className="cardText">{lblSrvc.getLabel(this.props.selectedLanguage, 'CITIZENS_TEXT')}</p>
                                      </div>
                                </div>
                            </li>
                        </div>
                        <div  className="col s12 m4">
                            <li className="scc-cards_item">
                                <div className="scc-card">
                                    <div className="scc-card_image"><img src={require('./comunication.jpg')} /></div>
                                      <div className="scc-card_content">
                                          <div className="cardHeadline">{lblSrvc.getLabel(this.props.selectedLanguage, 'ECONOMY')}</div>
                                          <p className="cardText">{lblSrvc.getLabel(this.props.selectedLanguage, 'ECONOMY_TEXT')}</p>
                                      </div>
                                </div>
                            </li>
                        </div>
                        <div  className="col s12 m4">
                            <li className="scc-cards_item">
                                <div className="scc-card">
                                <div className="scc-card_image"><img src={require('./meeting.jpg')} /></div>
                                      <div className="scc-card_content">
                                          <div className="cardHeadline">{lblSrvc.getLabel(this.props.selectedLanguage, 'PUBLIC_SECTOR')}</div>
                                          <p className="cardText">{lblSrvc.getLabel(this.props.selectedLanguage, 'PUBLIC_SECTOR_TEXT')}</p>
                                      </div>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
});

export default connect(mapStateToProps, {  })(CardsSection);