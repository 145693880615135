import React, { Component } from 'react';
import $ from 'jquery'; 
import  { connect } from 'react-redux';
import  lblSrvc from '../../labelService';
import { userConstants } from "../../_constants/userConstants";
import axios from "axios";
import Header  from '../Header/Header';
import Spiner from '../Spiner/Spiner'
import "react-datepicker/dist/react-datepicker.css";
import MySelect from '../MultiSelect/MySelect';
import AsyncMulti from '../MultiSelect/AsyncSelect';

const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '45px',
      height: '45px',
      boxShadow: state.isFocused ? null : null,
      border: '1px solid #26a69a !important'
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '45px',
      padding: '0 6px',
      'flex-direction': 'column',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        marginTop: '10px',
        backgroundColor: '#2ad2c2',
        color: 'white',
    }),
    placeholder: (provided, state) => ({
        display: 'none'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    menu: (provided, state) => ({
        ...provided,
        'background-color' : 'white',
        'z-index': '100000',
      }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '50px',
    }),
    option: (provided, { isSelected }) => {
        if(isSelected){
            return { 
                ...provided,
                backgroundColor: '#26a69a',
            };
        }
        return {
            ...provided,
        }
      },
};

class LicenceDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codes : { registers: [] },
            isAdmin : false,
            name : null, //Naziv
            nameEng : null, //Naziv
            levyId: null,
            ind_levy1: null,
            ind_levy2: null,
            ind_levy3: null,
            ind_levy4: null,
            ind_levy5: null,
            //error reporting 
            err_name : false, 
            err_name_msg : '',
            err_group: false, 
            err_group_msg : '',
            err_base: false, 
            err_base_msg : '',
            err_amount: false, 
            err_amount_msg : '',
            err_type: false, 
            err_type_msg : '',
            err_dateBegin: false, 
            err_dateBegin_msg : '',
            err_register_code: false,
            err_register_code_msg: '',
            startDate: new Date(),
            err_areaLvl1: false, 
            err_areaLvl1_msg : '',
            err_areaLvl2: false, 
            err_areaLvl2_msg : '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getRegisterCodes = this.getRegisterCodes.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        let { selectedLicence } = this.props;

        //check if is it admin 
        this.loadUser();
        const M = window.M;

        M.AutoInit();
        if (selectedLicence && selectedLicence > 0){
            this.setState({ isEdit : true});
            this.getLicenceById();
        }
        else this.getRegisterCodes();

    }

    loadUser() {
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data) {
            //this.props.history.push('/main');
        }else if(data.isAdmin){
            this.setState({ isAdmin: true});
        }
    }

    getSelectObject = (data, value) => {
        let obj = { value : 0, label: ''};

        for (let i = 0; i < data.length; i++) {
            if(data[i].value === value) 
                obj = data[i];
        }
        return [obj];
    }

    async getRegisterCodes(){
        await this.setState({ loading: true });

        axios.get("/api/getRegisterCodes",  {  })
            .then(res => {
                this.setState({ codes: res.data});
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
        });
    }

    async getLicenceById(){
        const M = window.M;

        await this.setState({ loading: true });

        axios.get("/api/getLicenceById", { params: {id : this.props.selectedLicence }} )
            .then(res => {
                this.setState({loading : false});
                this.setState({
                    name: res.data.Naziv, //Naziv
                    nameEng: res.data.Name,
                    levyId : res.data.DirektniNametId,
                    ind_levy1 : res.data.IndirektniNametIdId1,
                    ind_levy2 : res.data.IndirektniNametId2,
                    ind_levy3 : res.data.IndirektniNametId3,
                    ind_levy4 : res.data.IndirektniNametId4,
                    ind_levy5 : res.data.IndirektniNametId5,
                });
                
                setTimeout(() => {
                    M.updateTextFields();
                }, 300);

                this.getRegisterCodes();
            })
            .catch((error) => {
                this.setState({loading : false});
            }
        );
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value });
    }

    handleSelectChange = event => {
        this.setState({ [event.target.name] : event.target.value});
    }

    generateOptions = (data) => {
        if(!data) return [];
        return data.map( option => {
            return (<option value={option.value}> {option.label} </option>);
        });
    }

    validateField = async (toastTimeout) => {
        const M = window.M; 
        
        if (!this.state.register_code) {
            await this.setState({err_register_code : true, err_register_code_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_REG_NUMBER')});
            M.toast({html: this.state.err_register_code_msg, displayLength : toastTimeout});
            return true;
        }

        this.setState({err_register_code : false});

        if (!this.state.name) {
            await this.setState({err_name : true, err_name_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_NAME')});
            M.toast({html: this.state.err_name_msg, displayLength : toastTimeout});
            return true;
        }

        this.setState({err_name : false});

        /*if(!this.state.base){
            
            await this.setState({err_base : true, err_base_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_BASE')});
            M.toast({html: this.state.err_base_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_base : false});*/

        if(!this.state.amount){
            
            await this.setState({err_amount : true, err_amount_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_AMOUNT')});
            M.toast({html: this.state.err_amount_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_amount : false});


        if(!this.state.type){
            
            await this.setState({err_type : true, err_type_msg : lblSrvc.getLabel(this.props.selectedLanguage, 'TOAST_DEFINE_FIELD')+' '+lblSrvc.getLabel(this.props.selectedLanguage, 'REGISTER_TABLE_COLUMN_TYPE')});
            M.toast({html: this.state.err_type_msg, displayLength : toastTimeout});
            return true;
        }
        this.setState({err_type : false});
        
        return false;
    }

    onClose = () => {
        this.props.history.push("/search-page");
    }

    savedata = async () => {
        let toastTimeout = 3000;
        const M = window.M;

        try {
            let validate = false;
            await this.validateField(toastTimeout).then((result) => {
                validate = result;
            });
            
            if(validate) return;

            await this.setState({
                err_name : false,
                err_name_msg : '',
                err_scriptRegister: false, 
                err_scriptRegister_msg : '',
                err_group: false, 
                err_group_msg : '',
                err_base: false, 
                err_base_msg : '',
                err_amount: false, 
                err_amount_msg : '',
                err_type: false, 
                err_type_msg : '',
                err_dateBegin: false, 
                err_dateBegin_msg : '',
                err_register_code: false,
                err_register_code_msg: '',
                err_areaLvl1: false, 
                err_areaLvl1_msg : '',
                err_areaLvl2: false, 
                err_areaLvl2_msg : '',
            });
            //ispitai ostala polja na slican nacin koja trebaju 
            let form_data = {
                id: this.props.selectedLicence,
                name: this.state.name, //Naziv
                nameEng: this.state.nameEng,
                sector: this.state.sector, //SektorId
                location: this.state.location, //OpstinaId
                state: this.state.state, //Status
                price_from: this.state.price_from, //IznosOd
                price_to: this.state.price_to, //IznosDo
                type: this.state.type, // VrstaId
                bank_account: this.state.bank_account, //ZiroRacun
                scriptRegister: this.state.scriptRegister, //PropisNamet
                scriptAmount: this.state.scriptAmount, //PropisIznos
                groupName: this.state.groupName, //
                institution: this.state.institution, //Institucija
                dateBegin: this.state.dateBegin, //DatumPocetka
                frequencyPayment: this.state.frequencyPayment, //FrekvencijaPlacanjaId
                exceptions: this.state.exceptions, //Izuzeci
                paymentType: this.state.paymentType, //NacinPlacanjaId
                taxPayment: this.state.taxPayment, //TaksaPlacanjaId
                paymentObligor: this.state.paymentObligor, //ObveznikPlacanja
                base: this.state.base, //Osnovica
                amount: this.state.amount, //Iznos
                codeD: this.state.codeD,
                codeN: this.state.codeN,
                areaLvl1: this.state.areaLvl1,
                areaLvl2: this.state.areaLvl2,
                price_pre_from: this.state.price_pre_from,
                price_pre_to: this.state.price_pre_to,
                procedure: this.state.procedure,
                register_code: this.state.register_code
            };
            await this.setState({ loading: true });
            if(this.state.isEdit){
                //update register
                axios.post("/api/updateLicence", {params: { form_data : form_data }} )
                    .then(res => {
                        this.setState({loading : false});
                        this.props.history.push('/search-page');
                        M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_SUCCESS'), displayLength : toastTimeout});
                    })
                    .catch((error) => {
                        this.setState({loading : false});
                        M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_ERROR'), displayLength : toastTimeout});
                    }
                );
            }else {
                //add new register
                axios.post("/api/addRegister", {params: { form_data : form_data }} )
                    .then(res => {
                        this.setState({loading : false});
                        this.props.history.push('/search-page');
                        M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_SUCCESS'), displayLength : toastTimeout});
                    })
                    .catch((error) => {
                        this.setState({loading : false});
                        M.toast({html: lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE_DATA_ERROR'), displayLength : toastTimeout});
                    }
                );
            }
        }
        catch(err){}
    }


    getSaveBtnText(){
        if(this.state.isEdit) return lblSrvc.getLabel(this.props.selectedLanguage, 'SAVE');
        else return lblSrvc.getLabel(this.props.selectedLanguage, 'ADD');
    }

    getTitle(){
        if(!this.state.isAdmin) return lblSrvc.getLabel(this.props.selectedLanguage, 'USER_REGISTER_TITLE');
        else if(this.state.isEdit) return lblSrvc.getLabel(this.props.selectedLanguage, 'ADMIN_REGISTER_TITLE');
        else return lblSrvc.getLabel(this.props.selectedLanguage, 'ADMIN_ADD_REGISTER_TITLE');
    }

   
    render() {

        let {registers} = this.state.codes;
        let {levyId, ind_levy2, ind_levy3 , ind_levy4, ind_levy5} = this.state;
        let saveBtn = this.getSaveBtnText();
        let title = this.getTitle();

        return (
            <div className="scc-min-height">
                {this.state.loading &&
                    <Spiner></Spiner>
                }
                <Header />
                <div className="scc-page-container">
                    <div className="container" style={{marginTop : 70}}>
                        <div className="row centered-container">
                            <h4>{title}</h4>
                        </div>
                        <div className="row">
                            <div className="col-sm-9" style={{ margin: 25}}>
                                <form>
                                    <div className="col s12 m6">
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin} id="name" name="name" type="text" className="validate" value={this.state.name} onChange={this.handleInputChange} />
                                            <label for="register_code">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_NAME')+'*'}</label>
                                            {this.state.err_name_code &&
                                                <div className="lbl-err-txt">{this.state.err_name_msg}</div>
                                            }
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_DIR')}</span>
                                            <AsyncMulti 
                                                maxHeight={30}
                                                options={registers}
                                                value={this.getSelectObject(registers, levyId)}
                                                onChange={selected => this.setState({levyId : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND1')}</span>
                                            <AsyncMulti 
                                               maxHeight={30}
                                               options={registers}
                                               value={this.getSelectObject(registers, ind_levy2)}
                                               onChange={selected => this.setState({ind_levy2 : selected.value}) }
                                               isMulti={false}
                                               styles={selectCustomStyles}
                                               isDisabled={!this.state.isAdmin}
                                               isSearchable={true}
                                               allowSelectAll={false}
                                               hideSelectedOptions={false}
                                               lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND2')+'*'}</span>
                                            <AsyncMulti 
                                                maxHeight={30}
                                                options={registers}
                                                value={this.getSelectObject(registers, ind_levy3)}
                                                onChange={selected => this.setState({ind_levy3 : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                            {this.state.err_type &&
                                                <div className="lbl-err-txt">{this.state.err_type_msg}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col s12 m6">
                                        <div className="input-field col s12">
                                            <input disabled={!this.state.isAdmin}  id="nameEng" name="nameEng" type="text" className="validate" value={this.state.nameEng} onChange={this.handleInputChange}></input>
                                            <label for="nameEng">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_NAME_ENG')}</label>
                                            {this.state.err_nameEng &&
                                                <div className="lbl-err-txt">{this.state.err_nameEng_msg}</div>
                                            }
                                        </div>
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND3')}</span>
                                            <AsyncMulti 
                                                maxHeight={30}
                                                options={registers}
                                                value={this.getSelectObject(registers, ind_levy4)}
                                                onChange={selected => this.setState({ind_levy4 : selected.value}) }
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>
                                        {this.state.isAdmin &&

                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND4')}</span>
                                            <AsyncMulti 
                                                maxHeight={30}
                                                options={registers}
                                                value={this.getSelectObject(registers, ind_levy5)}
                                                onChange={selected => this.setState({ind_levy5 : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>}
                                        {this.state.isAdmin &&
                                        <div className="input-field col s12">
                                            <span className="multiselect-label">{lblSrvc.getLabel(this.props.selectedLanguage, 'LICENCE_COL_LEVY_IND5')}</span>
                                            <AsyncMulti 
                                                maxHeight={30}
                                                options={registers}
                                                value={this.getSelectObject(registers, ind_levy5)}
                                                onChange={selected => this.setState({ind_levy5 : selected.value}) }
                                                isMulti={false}
                                                styles={selectCustomStyles}
                                                isDisabled={!this.state.isAdmin}
                                                isSearchable={true}
                                                allowSelectAll={false}
                                                hideSelectedOptions={false}
                                                lang={this.props.selectedLanguage}
                                            />
                                        </div>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.isAdmin ?
                        <div className="row right-container" style={{paddingRight: 44}}>
                            <button className="btn waves-effect waves-light" type="button" name="searchBtn" style={{ marginRight : 30}} onClick={this.savedata}>
                                {saveBtn}
                            </button>
                          
                            <button className="btn waves-effect waves-light red lighten-3" type="button" name="searchBtn" style={{ marginLeft : 0}} onClick={this.onClose}>
                                {lblSrvc.getLabel(this.props.selectedLanguage, 'CANCEL')}
                            </button>
                        </div>
                        :  
                        <div className="row right-container" style={{paddingRight: 44}}>
                            <button className="btn waves-effect waves-light" type="button" name="back" style={{marginTop: 20, paddingLeft : 15}} onClick={this.onClose}>
                                {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_COMMENT_BACK')}
                                <i className="material-icons left" style={{fontSize : '1.6rem'}}>keyboard_backspace</i>
                            </button>
                        </div>
                  
                        }
                    </div>
                </div>
            </div>

        )
    }
}

/*const mapStateToProps = state => ({
    selectedLanguage: state.language,
    selectedLicence: state.registers.selectedLicence,

    return {
        selectedLicence, selectedLanguage
    };
});*/

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    const selectedLicence = state.licences.selectedLicence;

    return {
        loggedIn, user, selectedLanguage, selectedLicence
    };
}

const connectedLicenceDetails = (connect(mapStateToProps)(LicenceDetails));
export {connectedLicenceDetails as LicenceDetails};

//export default connect(mapStateToProps, { })(LicenceDetails);

