import React, { Component } from 'react';
import './Spiner.css'

export default class Spiner extends Component {
    render() {
        return (
        	<div>
        		<div className="loader"></div>
        		<div className="overlay">
        		</div>
        	</div>
        );
    }
}
