import { SEARCH } from './types';
import axios from 'axios';
import {userConstants} from '../_constants/userConstants';

export const getSearchedLicences = (value) => dispatch => {
  dispatch(loadingStatus(true));

  axios.post('/api/getSearchedLicences',value)
    .then(res => {
        dispatch({
            type: 'SEARCH_LICENCE',
            licences: res.data.licences,
            licencesCount: res.data.totalCount,
            searchedLicenceData: value,
        });
        dispatch(loadingStatus(false));
    }
    ,error => {
  });
};

export const loadingStatus = (bool) => {
  return {
      type: 'LOADING',
      isLoading: bool
  };
}
export const selectLicence = (id) => {
  return {
      type: 'SELECT_LICENCE',
      selectedLicence: id
  };
}
/*
export const setRegistersCount = (reg_count) => { 
	return { type: userConstants.REG_COUNT, reg_count };
}*/
