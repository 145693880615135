import React, { Component } from 'react';
import './Login.css'
import { BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import {history} from '../../_helpers/history';
import {userConstants} from '../../_constants/userConstants';
import {success, failure} from '../../actions/actions';
//import {Spiner} from '../Spiner/Spiner';
import {RegisterForm} from "./RegisterForm";
import Spiner from '../Spiner/Spiner'
import Header  from '../Header/Header';
import lblSrvc from '../../labelService';
import Footer  from '../Footer/Footer';
import {SendPassword} from "./SendPassword.js";

class Login extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            badLoginMsg: '',
            badLogin: false,
            loading : false,
            register: false,
            send_password : false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleOpenDialog = (e) => {
      this.setState({ openDialog: true });
    }

    handleCloseDialog = (e) => {
      this.setState({ openDialog: false });
    }

    async handleSubmit(e) {
        e.preventDefault();
        
        const { username, password, mail } = this.state;
        const { dispatch } = this.props;
        await this.setState({ loading: true });
        let user  = {'username' : mail, 'pass' : password, 'valid' : '10d', 'lang' : this.props.selectedLanguage};

        axios.post('/api/login', user)
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});

            if (server_data.msg == ''){
                //store token
                localStorage.setItem(userConstants.MEIS_EDU_TOKEN, server_data.idToken);
                
                let user_data = { username : user.username, id : server_data.id, isAdmin : server_data.isAdmin}
                localStorage.setItem(userConstants.USER_DATA, JSON.stringify(user_data));

                this.setState({ badLogin : false, badLoginMsg : ''});
                dispatch(success(mail));
                //check if it is admin
                if(server_data.isAdmin)
                    this.props.history.push("/search-page");
                else
                    this.props.history.push("/");
            }
            else {
            	this.setState({ badLogin : true, badLoginMsg : server_data.msg});
               // M.toast({html: server_data.msg, displayLength : 3000});
                dispatch(failure());
            }
        }
        ,error => {
        	this.setState({ loading: false, badLogin : true, badLoginMsg : 'Došlo je do greške prilikom prijavljivanja'});
            dispatch(failure());
        });
    }

    componentDidMount() {
        const M = window.M;

        setTimeout(() => {
            M.updateTextFields();
        }, 2000);
    }

    handleRegister = (e) => {
        this.setState({register : true});
    }

    handleLogin = (data) => {        
        this.setState({register : false, send_password : false});
        if (data)
            this.props.history.push("/");
    }

    handleSendPassword = (e) => {
        this.setState({send_password : true});
    }
    //<h6> {lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_MIN_FINANCY_MNE')}</h6>
    render() {
    	const {badLoginMsg, badLogin } = this.state;
        const { classes } = this.props;

        return (
            <div>
                <Header isLoginPage={true}/>
                {this.state.loading &&
                    <Spiner></Spiner>
                } 
                {(!this.state.register && !this.state.send_password) &&
                <div className="root-bg" style={{paddingTop: 90}}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m6 offset-m3">
                                <div className="card-panel" style={{marginTop : 65}}>
                                    <div className="centered-container">
                                        <img src={require("./memo.png")} width="70"/>
                                    </div>
                                    <div className="centered-container">
                                        
                                    </div>
                                    <div className="centered-container">
                                        <h6>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_REGISTER')}</h6>
                                    </div>
                                    <div className="centered-container" style={{marginBottom: '20px'}}>
                                        <h6>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_LOGIN')}</h6>
                                    </div>
                                    <div className="row">
                                        <form className="col s12">
                                            {badLogin &&
                                                <div className="centered-container" style={{marginBottom : 20}}>
                                                    <div className="lbl-err-txt">{badLoginMsg}</div>
                                                </div>
                                            }
                                            <div className="row mlp0">
                                                <div className="input-field col s12 mtp0">
                                                    <input id="mail" type="text" name="mail" onChange={this.handleChange}/>
                                                    <label htmlFor="mail">{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_USERNAME')}</label>
                                                </div>
                                            </div>
                                            <div className="row mlp0">
                                                <div className="input-field col s12 mtp0">
                                                    <input id="password" type="password" name="password" onChange={this.handleChange}/>
                                                    <label htmlFor="password">{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_PASSWORD')}</label>
                                                </div>
                                            </div>
                                            <div className="row mlp0">
                                                <div className="input-field col s12 mtp0">
                                                    <a href="javascript:void(0)" className="waves-effect waves-light btn darken-4"
                                                        onClick={this.handleSubmit}>{lblSrvc.getLabel(this.props.selectedLanguage, 'LOGIN')}</a>
                                                </div>
                                            </div>

                                            <div className="row centered-container" style={{marginLeft: '20px', marginRight: '20px'}}>
                                                <div>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_NO_ACCOUNT')}<a style={{marginLeft: 4}} href="javascript:void(0)" onClick={this.handleRegister}>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_REGISTRATION')}</a></div>
                                            </div>
                                            <div className="row centered-container" style={{marginLeft: '20px', marginRight: '20px'}}>
                                                <div>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_FORGOTTEN_PASSWORD')}<a style={{marginLeft: 4}} href="javascript:void(0)" onClick={this.handleSendPassword}>{lblSrvc.getLabel(this.props.selectedLanguage, 'SCC_SEND_VIA_MAIL')}</a></div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.register && 
                    <RegisterForm history={history} onHandleLogin={this.handleLogin}></RegisterForm>
                }
                {this.state.send_password && 
                    <SendPassword history={history} onHandleLogin={this.handleLogin}></SendPassword>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    return {
        loggedIn, user, selectedLanguage
    };
}


const connectedLogin = (connect(mapStateToProps)(Login));
export {connectedLogin as Login};
