import React, { Component } from 'react'
import  { connect } from 'react-redux';
import  lblSrvc from '../../labelService';
import './header.css';
import  { selectLanguage } from '../../actions/languageActions';
import { userConstants } from "../../_constants/userConstants";
import {Link} from 'react-router-dom';
import { failure} from '../../actions/actions';
import { uputstvo } from './uputstvo.pdf';

export class Header extends Component {

    constructor(props){
        super(props);
        this.dropDown = React.createRef();
        this.state = {
            icon_name: 'mne',
            username: '',
            loggedIn: false,
            isAdmin: false
        }
    }

    componentDidMount() {
        const M = window.M;
        
        let sidenav = document.querySelector('#slide-out');
        M.Sidenav.init(sidenav, {});
        let dropdowns = document.querySelectorAll('.dropdown-trigger');
    
        let options = {
            inDuration: 300,
            outDuration: 300,
            hover: false, 
            coverTrigger: false, 
        };
        M.Dropdown.init(dropdowns, options);
        this.setState({ icon_name : this.props.selectedLanguage.toLowerCase()});
        this.loadUser();
    }

    loadUser() {
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data) return;
        if( !data.isAdmin) {
            this.setState({ username : data.username, loggedIn : true, isAdmin: false});
        }else {
            this.setState({ username : data.username, loggedIn : true, isAdmin: true});
        }
    }
    
    selectChange = (e) => {
        this.props.selectLanguage(e.currentTarget.name);
        this.setState({ icon_name : e.currentTarget.name.toLowerCase()});
    }

    onChange = (event) => {
        this.setState({ [event.target.name] : event.target.value})
    }

    onLogOut = (e) =>{
        localStorage.clear()
        this.setState({ username : '', loggedIn : false});
    }

    generateLoginBtn = () => {
        if(!this.state.loggedIn){
            return (
                <Link className="scc-login-button openSansHeader" to="/login">{lblSrvc.getLabel(this.props.selectedLanguage, 'LOGIN')}</Link>
            );
        }else {
            return (
                <Link className="scc-login-button openSansHeader" to="/" onClick={ () => this.onLogOut() }>{lblSrvc.getLabel(this.props.selectedLanguage, 'LOGOUT')}</Link>
            );
        }
    }

    getLogo = () => {
        let lang = this.props.selectedLanguage;

        if(lang === 'MNE') 
            return (
                <img src={require("./min_fin5.png")} width="225" className="scc-header-image"/>
            );
        else 
            return (
                <img src={require("./min_fin5_eng2.png")} width="225" className="scc-header-image"/>
            );
    }


    render() {
        let loginButton = this.generateLoginBtn();
        return (
            <header>
                <nav className="nav-wrapper white navbar-transition cool-navbar z-depth-0" style={{height : 130}}>
                    
                    <div className="">
                        <a className="sidenav-trigger" data-target="slide-out" >
                            <i className="material-icons blue-text" >menu</i>
                        </a> 
                    </div>

                    <div className="container" style={{width : '90% !important', marginLeft : '0px !important'}}>
                        <div className="nav-wrapper row">

                            <div className="col s3 centered-container" style={{ marginLeft : -0, marginTop : 0}}>
                                 <a href="javascript:void(0)" className="brand-logo" style={{marginLeft : 0}}>
                                    {this.getLogo()}
                                </a>
                            </div>
                            <div className="right hide-on-med-and-down col s9 right-container" style={{marginRight : 10}}>
                                { this.props.isLoginPage ? 
                                    <ul className="right">
                                        <li> <Link className="black-text openSansHeader" to="/">{lblSrvc.getLabel(this.props.selectedLanguage, 'HOME')}</Link></li>
                                    </ul>
                                :<ul className="right">
                                    <li> <Link className="black-text openSansHeader" to="/" >{lblSrvc.getLabel(this.props.selectedLanguage, 'HOME')}</Link></li>
                                    <li> <Link className="black-text openSansHeader" to="/search-page" onClick={() => {this.props.openComments && this.props.onCloseComments()}}> {lblSrvc.getLabel(this.props.selectedLanguage, 'SEARCH')} </Link></li>
                                    { this.state.isAdmin ? <li> <Link className="black-text openSansHeader" to="/reports"> {lblSrvc.getLabel(this.props.selectedLanguage, 'REPORTS')} </Link></li> : null}
                                    { this.state.isAdmin ? <li> <a className="black-text openSansHeader" href={require('./uputstvo_admin.pdf')} target = "_blank"> {lblSrvc.getLabel(this.props.selectedLanguage, 'USER_MANUAL')} </a></li> : null}
                                    { !this.state.isAdmin ? <li> <a className="black-text openSansHeader" href={require(this.props.selectedLanguage=='ENG'?'./uputstvo_kor.pdf':'./uputstvo.pdf')} target = "_blank"> {lblSrvc.getLabel(this.props.selectedLanguage, 'USER_MANUAL')} </a></li> : null}
                                        <li>
                                            <div className="filter-group" style={{ position : 'relative', padding : 0, display : 'inline'}}>
                                                {loginButton}
                                            </div>
                                        </li>
                                </ul>}
                            </div>
                            <div className="col s2 right-container" >
                                
                                    <a className="scc-header-username hide-on-med-and-down openSansHeader">{this.state.username}</a>

                                    <a href="javascript:void(0)" name="MNE" onClick={ (e) => this.selectChange(e)} style={{marginLeft : '10px', marginRight : '10px'}}
                                    className="centered-container">
                                        <img src={require('./mne1.png')} width="30"/>
                                    </a>
                                    <a href="javascript:void(0)" name="ENG" onClick={ (e) => this.selectChange(e)}
                                    className="centered-container">
                                        <img src={require('./uk1.png')} width="30" />
                                    </a>

                                
                            </div>
                        </div>
                    </div>
                    
                </nav>
                
                <ul id="slide-out" className="sidenav">
                    <li><Link className="black-text waves-effect" to="/">{lblSrvc.getLabel(this.props.selectedLanguage, 'HOME')}</Link></li>
                    <li><Link className="black-text waves-effect" to="/search-page"> {lblSrvc.getLabel(this.props.selectedLanguage, 'SEARCH')} </Link></li>
                    <li>
                        <div className="filter-group" style={{ position : 'relative', paddingLeft : 20, display : 'inline'}}>
                            {loginButton}
                        </div>
                    </li>
                    <li>
                    <a className="scc-header-username">{this.state.username}</a>
                                    
                    </li>
                </ul>
               
            </header>
        )
    }
}

const mapStateToProps = state => ({
    selectedLanguage: state.language,
    loggedIn: state.authentication.loggedIn,
    username: state.authentication.user, 
});

export default connect(mapStateToProps, { selectLanguage, failure })(Header);